import { Component, OnInit } from '@angular/core';
import { Toast } from '../../utils/toast';
import { Client, Body14 } from '../../../api.client';

@Component({
  selector: 'app-our-guidelines',
  templateUrl: './our-guidelines.component.html',
  styleUrls: ['./our-guidelines.component.scss']
})
export class OurGuidelinesComponent implements OnInit {

  resources: Body14[];

  constructor(private apiClient: Client) { }

  ngOnInit(): void {
    this.getResources();
  }

  getResources(): void {

    this.apiClient.resources2().subscribe(d => {
      // Move the Brown Girls resource to the top of the list. ID: 6228e1a74259a4bdc5eb043a

      const BROWN_GIRLS_RESOURCE = "6228e1a74259a4bdc5eb043a";

      const resources = []

      d.body.forEach(resource => resource._id === BROWN_GIRLS_RESOURCE ? resources.unshift(resource) : resources.push(resource));

      // this.resources = d.body;

      this.resources = resources;
    })

  }

  downloadResource(): void {
    Toast.fire({
      title: "Downloading...",
      icon: 'success'
    });
  }

}
