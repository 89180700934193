import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-post-job',
  templateUrl: './post-job.component.html',
  styleUrls: ['./post-job.component.scss']
})
export class PostJobComponent implements OnInit {
  isViewable: boolean;

  constructor() {

  }

  ngOnInit(): void {
    this.isViewable = false;
  }

  getIsViewable() {
    return this.isViewable;
  }

  showAirtableForm() {
    this.isViewable = true;
  }

}
