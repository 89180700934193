<div class="main">
    <div class="row">
        <div class="column">
            <form [formGroup]="passwordRecoveryForm" (ngSubmit)="recoverPassword()">

                <div class="form-group mb-5">
                    <label class="form__label" for="email">Email</label>
                    <input type="email" class="form-control" id="email" formControlName="email">
                    <div class="error">
                        <small
                            *ngIf="passwordRecoveryForm.get('email').errors && passwordRecoveryForm.get('email').touched">
                            Email is required</small>
                    </div>

                </div>

                <div class="form-group mb-5">
                    <label class="form__label" for="password">Password</label>
                    <input type="password" class="form-control" id="password" formControlName="password">
                    <div class="error">
                        <small
                            *ngIf="passwordRecoveryForm.get('password').errors && passwordRecoveryForm.get('password').touched">
                            Password is required</small>
                    </div>

                </div>
                <div class="form-group mb-5">
                    <label class="form__label" for="confirmPassword">Confirm Password</label>
                    <input type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword">
                    <div class="error">
                        <small
                            *ngIf="passwordRecoveryForm.get('confirmPassword').errors && passwordRecoveryForm.get('confirmPassword').touched ">
                            Passwords must match</small>

                    </div>

                </div>
                <button type="submit" class="btn btn-primary btn-lg btn-block">RESET
                    PASSWORD</button>
            </form>
        </div>
        <div class="column">
            <a href="https://storyset.com/">
                <img src="../../../assets/recover/password2.png" class="column_img">
            </a>
        </div>



    </div>
</div>