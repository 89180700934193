import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { Router } from '@angular/router';
import { Client, FileParameter, ImageType, ImageType2 } from '../../../api.client';
import { Toast } from "../../utils/toast";
import { AuthService } from "../../services/auth.service";
import { wordCount } from '../../utils/words.validation';
import { Photo } from '../shared/cropper/cropper.component';

import swal from 'sweetalert2';

interface SearchArrays {
  position: Array<string>,
  otherExperience: Array<string>,
  documentaryExperience: Array<string>,
  BasedIn: Array<string>,
  languages: Array<string>,
  selfIdentification: Array<string>,
  racial: Array<string>

}
@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent implements OnInit {
  profileForm: FormGroup;
  currentUser: any = {};
  profileCreated: boolean = true;
  activelyLookingOriginalValue: any;
  id: string = ''
  action: string = ''
  wordCount: any;
  words: any;
  webPattern = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  //https://www.regextester.com/94502

  profile: ImageType2 = ImageType2.Profile;
  poster1: ImageType2 = ImageType2.Poster1;
  poster2: ImageType2 = ImageType2.Poster2;
  poster3: ImageType2 = ImageType2.Poster3;

  images = {
    profile: 'assets/profileform/default_pp.svg',
    poster1: 'assets/profileform/poster.svg',
    poster2: 'assets/profileform/poster.svg',
    poster3: 'assets/profileform/poster.svg'
  }


  constructor(private fb: FormBuilder, private apiClient: Client, public auth: AuthService, private router: Router) {
    this.profileForm = this.fb.group({
      contactEmail: ['', [Validators.email]],
      bio: ['', [ wordCount(300, false)]],
      preferredPronouns: [''],
      links: this.fb.group({
        imdb: ['', [Validators.pattern(this.webPattern)]],
        linkedin: ['', [Validators.pattern(this.webPattern)]],
        website: ['', [Validators.pattern(this.webPattern)]],
        customLink: ['', [Validators.pattern(this.webPattern)]],
      }),
      otherIdentities: [''],
      position:  this.fb.array([],Validators.required),
      languages: this.fb.array([]),
      selfIdentification: this.fb.array([]),
      BasedIn: this.fb.array([]),
      racial: this.fb.array([], Validators.required),
      receiveJobNotifs: [false, Validators.required], // https://stackoverflow.com/a/44629734
      activelyLooking: [0, Validators.required], // https://stackoverflow.com/a/44629734
      documentaryExperience: this.fb.array([],Validators.required),
      otherExperience: this.fb.array([]),
      images: this.fb.group({
     
        posters: this.fb.group({
          poster1: this.fb.group({ referenceLink: ['', [Validators.pattern(this.webPattern)]], description: [''], credits: [''] }),
          poster2: this.fb.group({ referenceLink: ['', [Validators.pattern(this.webPattern)]], description: [''], credits: [''] }),
          poster3: this.fb.group({ referenceLink: ['', [Validators.pattern(this.webPattern)]], description: [''], credits: [''] }),
        }),
      }),
      name: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
    });

    this.id = this.auth.getId();
    this.getProfile();

  }

  ngOnInit(): void {

  }

  isVerifiable() {
    // Checks if the profile has uploaded/entered all the necessary fields like images, documentary experience, etc
    const profile = this.currentUser.body;

    if ((profile.images?.profileImage ?? "") === "") return false;
    if (profile.documentaryExperience.length === 0) return false;

    return true;
  }

  onSubmit(action: string) {
    if (action === "save") {
      if (this.profileForm.valid) {
        if (this.profileCreated) {
          this.editProfile(this.id).subscribe(d => {
            if (this.currentUser?.body?.verified) {
              Toast.fire({
                title: d.body,
                icon: 'success'
              });
            } else {
              if (this.isVerifiable()) {
                Toast.fire({
                  title: "Please allow us few days to verify your profile! You will receive an email once you're verified.",
                  icon: 'info',
                  position: "center",
                  timer: undefined,
                  showConfirmButton: true,
                });
              } else {
                Toast.fire({
                  title: "Your profile is saved, but we will not be able to verify you until all required fields are filled out. Required fields include Documentary Experience and a profile image.",
                  icon: 'info',
                  position: "center",
                  timer: undefined,
                  showConfirmButton: true,
                });
              }
            }            

            this.getProfile();
          }, err => {
            console.log(err)
            Toast.fire({
              title: "Something went wrong!",
              icon: 'error'
            });
          });
        } else {
          this.createProfile();
        }
      } else {
        Toast.fire({
          // title: "Please review your application!",
          title: "Not All Required Fields Have Been Filled Out!",
          icon: 'error'
        });
      }

    }
    else if (action === 'delete') {
      this.deleteProfile(this.id)
    }
  }

  fixActivelyLooking() {
    if (this.profileForm.value.activelyLooking === true) {
      if (this.activelyLookingOriginalValue > 0) {
        this.profileForm.value.activelyLooking = this.activelyLookingOriginalValue;
      } else {
        this.profileForm.value.activelyLooking = -1;
      }
    } else if (this.profileForm.value.activelyLooking === false) {
      this.profileForm.value.activelyLooking = 0;
    }
  }

  uploadImage(imageType: ImageType, file: FileParameter) {
    this.apiClient.photo(this.id, imageType, file).subscribe(d => {
      console.log('Updated');
    });
  }

  onImageSave(event: boolean) {

  }


  createProfile() {
    this.apiClient.profile({ profile: this.profileForm.value }).subscribe(d => {
      Toast.fire({
        title: "Profile created!",
        icon: 'success'
      });
      this.profileCreated = true;
    }, err => {
      console.log(err)
      Toast.fire({
        title: "Something went wrong!",
        icon: 'error'
      });
    });

  }

  editProfile(id: string) {
    this.fixActivelyLooking();
    return this.apiClient.profile4(id, { profile: this.profileForm.value });
  }

  setImages(images: any) {

    if (images == null) return;

    this.images.profile = images.profileImage ? `${images.profileImage}?${Date.now()}` : this.images.profile;
    this.images.poster1 = images.posters.poster1.image !== "" ? `${images.posters.poster1.image}?${Date.now()}` : this.images.poster1;
    this.images.poster2 = images.posters.poster2.image !== "" ? `${images.posters.poster2.image}?${Date.now()}` : this.images.poster2;
    this.images.poster3 = images.posters.poster3.image !== "" ? `${images.posters.poster3.image}?${Date.now()}` : this.images.poster3;

  }

  getProfile() {
    this.apiClient.profile3(this.id).subscribe(d => {
      this.profileCreated = true;
      this.currentUser = d
      this.activelyLookingOriginalValue = this.currentUser.body.activelyLooking;

      this.setImages(d.body.images);


      this.profileForm.patchValue({
        name: this.currentUser.body.user.name,
        lastName: this.currentUser.body.user.lastName,
        contactEmail: this.currentUser.body.user.email,
        receiveJobNotifs: this.currentUser.body?.receiveJobNotifs ?? false,
        activelyLooking: this.currentUser.body?.activelyLooking ?? 0,
        ...this.currentUser.body
      });

      this.loadArrays({
        selfIdentification: d.body.selfIdentification,
        BasedIn: d.body.BasedIn,
        position: d.body.position,
        otherExperience: d.body.otherExperience,
        documentaryExperience: d.body.documentaryExperience,
        languages: d.body.languages,
        racial: d.body.racial,
      });


    }, err => {
      console.log(err);
    })
  };

  private clearArrays(arrays: string[]) {

    arrays.forEach(d => {
      const arr = this.profileForm.get(d) as FormArray;
      arr.clear();
    })
  }


  private loadArrays(arrays: SearchArrays) {
    Object.keys(arrays).forEach(d => {
      const array = this.profileForm.get(d) as FormArray;
      array.clear();
      arrays[d].forEach(d => {
        array.push(new FormControl(d));
      });
    });
  }


  deleteProfile(id: string) {
    this.apiClient.user3(id).subscribe(d => {
      Toast.fire({
        title: d.body,
        icon: 'success'
      });
      this.auth.logOut();
      this.router.navigate(['/home']);
      window.location.reload();

    }, err => {
      console.log(err)
      Toast.fire({
        title: "Something went wrong!",
        icon: 'error'
      });

    })
  }

  onSelectFile(event) {
    const reader = new FileReader();
    const file: FileParameter = { data: event.target.files[0], fileName: 'resume.pdf' }


    this.apiClient.resume(this.id, file).subscribe(d => {


      Toast.fire({
        title: 'Resume loaded successfully!',
        icon: 'success'
      })

    }, error => {

      Toast.fire({
        title: 'There was an error with the file.',
        text: 'Please verify for invalid format.',
        icon: 'error'
      })

    });


  }

  reloadImg(event: any) {
    this.getProfile();
  }

  deleteImage(img: ImageType2){
    this.apiClient.profile3(this.id).subscribe(d =>{

      this.apiClient.photo2(d.body._id,img).subscribe(d =>{
        Toast.fire({
          title: 'Photo deleted successfully!',
          icon: 'success'
        })

        this.getProfile();

      }, error => {
        Toast.fire({
          title: 'There was an error .',
          text: 'Please verify.',
          icon: 'error'
        })

      });



    })



  }



  imageWasCropped(event: Photo) {

    swal.fire({
      title: 'Please Wait !',
      text: 'Uploading photo',
      allowOutsideClick: false
  });
    swal.showLoading();

    this.editProfile(this.id).subscribe( _data => {
      this.apiClient.photo(event.id, event.photoType, {
        data: event.imageData,
        fileName: 'image-upload'
      }).subscribe(data => {
        this.profileForm.reset();
        this.clearArrays([
          'selfIdentification',
            'BasedIn',
            'position',
            'otherExperience',
            'documentaryExperience',
            'languages',
            'racial'
        ]);
        this.getProfile();

        Toast.fire({
          title: 'Image was successfully updated',
          icon: 'success'
        })

      }, err => {
        Toast.fire({
          title: 'Image was not updated, please try again.',
          icon: 'error'
        })
      });
    }, err => {
      Toast.fire({
        title: 'Image was not updated, please try again.',
        icon: 'error'
      })
    });
  }

}





