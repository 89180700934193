import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Client, Anonymous7 } from '../../../api.client';
import { AuthService } from '../../services/auth.service';
import { BookmarksService, Profile } from '../../services/bookmarks.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LinksService } from '../../services/links.service';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


interface Poster {
  url: string,
  image: string,
  title: string
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  id: string;

  currentUser: any = {};
  currentId: string;
  biography: string = '';
  pron: string;
  languages: string[];
  ident: string[];
  position: string[];
  name: string;
  lastname: string;
  profimg: string = null;
  activelyLooking: number = 0;

  poster1: string;
  poster2: string;
  poster3: string;
  reflink1: string;
  reflink2: string;
  reflink3: string;

  title1: string;
  title2: string;
  title3: string;

  credits1: string;
  credits2: string;
  credits3: string;

  currentlink: string;
  imdb: string;
  linkedin: string;
  website: string;
  customlink: string;
  link: string;
  mail: string;
  ubi: string[];
  ubi2: string;
  resume: string;


  constructor(
    private apiClient: Client,
    public auth: AuthService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private bookmarkService: BookmarksService,
    private sanitizer: DomSanitizer,
    private linkService: LinksService,
    private metaService: Meta,
    private title: Title,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe((routeParams) => {
      this.getProfile(this.getRouteId());
    });
    this.metaService.addTag({ name: 'robots', content: 'index,follow' });

    this.id = this.auth.getId();
    this.link = window.location.href;
  }

  ngOnDestroy(): void {
    this.title.setTitle('BIPOC EDITORS');

    this.metaService.removeTag('name="description"');

    this.metaService.removeTag('name="og:title"');
    this.metaService.removeTag('name="og:description"');
    this.metaService.removeTag('name="og:image"');
    this.metaService.removeTag('name="og:url"');

    this.metaService.removeTag('name="twitter:title"');
    this.metaService.removeTag('name="twitter:description"');
    this.metaService.removeTag('name="twitter:image"');
    this.metaService.removeTag('name="twitter:card"');

  }

  getRouteId(): string {
    const tempId = this.router.url.split("/", 3)[2];

    if (tempId.includes("-")) {
      return tempId.split("-").pop();
    } else {
      return tempId;
    }
  }

  getProfile(id: string) {
    this.apiClient.profile3(id).subscribe((d) => {

      this.metaService.addTag({ name: 'description', content: d.body.bio });
      this.title.setTitle(`${this.titleCaseWord(d.body.user.name)} ${this.titleCaseWord(d.body.user.lastName)}`);

      this.metaService.addTag({ name: 'og:title', content: `${this.titleCaseWord(d.body.user.name)} ${this.titleCaseWord(d.body.user.lastName)}` });
      this.metaService.addTag({ name: 'og:description', content: d.body.bio });
      this.metaService.addTag({ name: 'og:image', content: d.body.images.profileImage });
      this.metaService.addTag({ name: 'og:url', content: `https://bipocdoceditors.com/profile/${d.body._id}` });

      this.metaService.addTag({ name: 'twitter:title', content: `${this.titleCaseWord(d.body.user.name)} ${this.titleCaseWord(d.body.user.lastName)}` });
      this.metaService.addTag({ name: 'twitter:description', content: d.body.bio });
      this.metaService.addTag({ name: 'twitter:image', content: d.body.images.profileImage });
      this.metaService.addTag({ name: 'twitter:card', content:"summary_large_image" });



      this.currentId = d.body._id;
      this.activelyLooking = d.body?.activelyLooking ?? 0;
      this.pron = d.body.preferredPronouns;
      this.biography = d.body.bio;
      this.languages = d.body.languages;
      this.ident = d.body.racial.filter(r => r !== "Other (BIPOC)");
      this.position = d.body.position;
      this.name = d.body.user.name;
      this.lastname = d.body.user.lastName;
      this.profimg = d.body.images.profileImage;
      this.linkedin = this.linkService.validateURL(d.body.links.linkedin);
      this.imdb = this.linkService.validateURL(d.body.links.imdb);
      this.website = this.linkService.validateURL(d.body.links.website);
      this.customlink = this.linkService.validateURL(d.body.links.customLink);
      this.poster1 = d.body.images.posters.poster1.image;
      this.poster2 = d.body.images.posters.poster2.image;
      this.poster3 = d.body.images.posters.poster3.image;
      this.reflink1 = this.linkService.validateURL(
        d.body.images.posters.poster1.referenceLink
      );
      this.reflink2 = this.linkService.validateURL(
        d.body.images.posters.poster2.referenceLink
      );
      this.reflink3 = this.linkService.validateURL(
        d.body.images.posters.poster3.referenceLink
      );
      this.title1 = d.body.images.posters.poster1.description;
      this.title2 = d.body.images.posters.poster2.description;
      this.title3 = d.body.images.posters.poster3.description;

      this.credits1 = d.body.images.posters.poster1.credits;
      this.credits2 = d.body.images.posters.poster2.credits;
      this.credits3 = d.body.images.posters.poster3.credits;


      this.mail = d.body.contactEmail;
      this.ubi = d.body.BasedIn;
      this.resume = d.body?.resume;
      this.ubi2 = this.ubi.join(', ');

      this.linkService.validateURL(this.imdb);

      const nameSanitizer = (n: string) => {
        const filterRegex1 = /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_]/g;
        const filterRegex2 = /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;

        return this.titleCaseWord(n).replace(filterRegex1, "").split(" ").filter(x => x.replace(filterRegex2, '').trim() !== "").join("-");
      }

      const firstNameFiltered = nameSanitizer(this.name);
      const lastNameFiltered = nameSanitizer(this.lastname);

      const staticPath = `/profile/${firstNameFiltered}-${lastNameFiltered}-${this.getRouteId()}`;

      this.link = `${window.location.origin}${staticPath}`;

      if (window.location.href !== this.link) {
        this.router.navigate([staticPath]);
      }
    });
  }

  titleCaseWord(s: string) {
    if (!s) return s;
    return s.trim().split(" ").map(word => `${word[0].toUpperCase()}${word.substr(1)}`).join(" ");
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  isEditable(): boolean {
    if (this.id === this.getRouteId()) return true;
    return false;
  }

  isResume(): boolean {
    if (this.resume == null) return false;
    else return true;
  }

  isContact(): boolean {
    if (this.mail == null) return false;
    else return true;
  }

  profileForm() {
    this.router.navigate(['/profileform']);
  }

  capitalize(s: string): string {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  addBookmark(): void {
    this.bookmarkService.addBookmark({
      _id: this.currentId,
      Name: `${this.capitalize(this.name)} ${this.capitalize(this.lastname)}`,
      BasedIn: this.ubi.join(', '),
      Position: this.position.join(', '),
      Email: this.mail || '',
      Link: `${'https://bipocdoceditors.com/profile/'}${this.currentId}`,
    });
  }

  removeBookmark(): void {
    this.bookmarkService.removeBookmark(this.currentId);
  }

  findInBookmarks(): Boolean {
    return this.bookmarkService.findBookmark(this.currentId);
  }

  isReflink(reflink: string): Boolean {
    if (reflink === undefined) return false;
    else return true;
  }

  sanitizeLink() {
    return this.sanitizer.bypassSecurityTrustUrl(
      'whatsapp://send?text=' + this.link
    );
  }


  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }


}
