<div class="container-fluid">
    <div class="row">

        <!-- Image col -->

        <div class="col-lg-6  col-sm-12 d-flex align-items-center d-flex justify-content-center" >
            
            <div class="d-flex justify-content-center" style="flex-direction:column;">
                <img src="assets/BDE3.jpg" class="imgStyle" >  
                <div>
                    <span style="font-family: Montserrat; color: darkgray;" class="span_credits">Credit: © 2019 Sundance Institute | Photo by Jen Fairchild</span>
                </div>
            </div>

        </div>
        


        <!-- Boxes col -->
        <div class="col-lg-6 col-sm-12 align-items-center">
            
            <!-- Title -->
            <div class="d-flex justify-content-md-center title_box">
                <h1 class="mr-4 title_font" style="color:#272343;">CONTACT</h1>

                <h1 class=" title_font" style="color:#FFB803;"> US</h1>
            </div>
            
            <div class="d-flex justify-content-center">

                <!-- Underline -->
                <hr style="width: 217px; border-width: 2px; border-color: lightgray;">

            </div>



            <!-- Form Groups -->

            <div>
                <form [formGroup]="contact" (ngSubmit)="contactf()">

                    <div class="form-group px-5">
                        <label class="placeholder_style" for="name">Name</label>
                        <input type="text" class="form-control" id="name_input" formControlName="name" />
                        <div style="position: absolute;"
                            *ngIf="contact.get('name').touched && contact.get('name').invalid">
                            <small *ngIf="contact.get('name').errors"
                                style="font-weight: 300; font-size: 15px;  font-family: 'Montserrat', sans-serif; color: darkred;">
                                Name is required</small>
                        </div>
                    </div>

                    <div class="form-group px-5 mt-5">
                        <label class="placeholder_style" for="email">Email</label>
                        <input type="email" class="form-control" id="email_input" formControlName="email"
                            formControlName="email" />
                        <div style="position: absolute;"
                            *ngIf="contact.get('email').touched && contact.get('email').invalid">
                            <small *ngIf="contact.get('email').errors"
                                style="font-weight: 300; font-size: 15px;  font-family: 'Montserrat', sans-serif; color: darkred;">Email
                                is
                                required</small>
                            <div style="font-weight: 300; font-size: 15px;  font-family: 'Montserrat', sans-serif; color: darkred;"
                                *ngIf="contact.get('email').errors.email"> Email must be a valid email Address</div>
                        </div>
                    </div>

                    <div class="form-group px-5 mt-5">
                        <label class="placeholder_style" for="message">Message</label>
                        <textarea required class="form-control" id="message" formControlName="message"
                            aria-label="With textarea" maxlength="600"></textarea>
                        <div style="position: absolute;"
                            *ngIf="contact.get('message').touched && contact.get('message').invalid">
                            <small *ngIf="contact.get('message').errors"
                                style="font-weight: 300; font-size: 15px;  font-family: 'Montserrat', sans-serif; color: darkred;">
                                Minimum length of 20 characters.</small>


                        </div>

                    </div>

                    <div class="d-flex justify-content-center mx-5 mt-5">
                        <button type="submit" class="btn btn-primary btn-lg btn-block">SUBMIT</button>
                    </div>

                </form>






            </div>
