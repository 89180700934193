<div class="main">
	<!-- TOP PART (TITLE & DESCRIPTION) -->
	<div class="row d-flex justify-content-center mb-5">

		<!-- TITLE -->
		<div class="col-12">
			<h1 class="text-center mb-2 resp_title" style="font-weight: 550; font-size: 48px; color:#272343;">POST A JOB
			</h1>
			<!-- Underline -->
			<div class="d-flex justify-content-center">
				<hr class="mb-4" style="width: 217px; border-width: 50px; border-color: lightgray;">
			</div>
		</div>

		<!-- DESCRIPTION -->
		<div class="col-12 text-center mb-4">
			<h3 class="description">
				<span class="d-block mb-3">Producers and Production Companies!</span>
				<span class="d-block">Are you hiring?</span>
			</h3>
		</div>

		<!-- DESCRIPTION -->
		<div class="col-12">
			<p class="description">
				If you are looking to fill a documentary post-production position like editor, associate editor, or assistant editor,
				submit your job post below! Your job posting will be sent out to all our members who have opted in to receive postings.
			</p>
		</div>
	</div>

	<!-- /TOP PART (TITLE & DESCRIPTION) -->

	<!-- Associate Editor Initiative-->
	<div class="row mb-5 mt-5">
		<div *ngIf=!getIsViewable() class="align-items-between guidelines justify-content-center d-flex">
			<div class="d-flex align-items-center justify-content-center"
				style="background-color: #e3f6f5;">
				<div class="text-center p-2">
					<div class="mx-auto text-center w-75">
						<button class="btn-primary btn p-3 mb-3" style="color:#272343; white-space: normal;" (click)="showAirtableForm()">SUBMIT A JOB POST TO BIPOC DOC EDITORS</button>
					</div>
					<span class="font-size: x-small;">
						Please allow a few days for your post to be vetted and sent to members.
					</span>
				</div>
			</div>
		</div>

		<div *ngIf=getIsViewable() class="row guidelines-content">
			<iframe sandbox="allow-same-origin allow-forms allow-modals allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-top-navigation allow-scripts" class="airtable-embed" src="https://airtable.com/embed/shrZALZ68CThqCxgh?backgroundColor=yellow" frameborder="0" onmousewheel="" width="100%" height="800" style="background-image: url('/assets/general/loading.svg'); background-repeat: no-repeat; background-position: center; border: 1px solid #ccc;"></iframe>
		</div>

	</div>

	<!-- /Associate Editor Initiative-->

	<!-- TOP PART (TITLE & DESCRIPTION) -->
	<div class="row d-flex justify-content-center mb-5">

		<!-- TITLE -->
		<div class="col-12">
			<h1 class="text-center mb-2 resp_title" style="font-weight: 550; font-size: 48px; color:#272343;">
			</h1>
			<!-- Underline -->
			<div class="d-flex justify-content-center">
				<hr class="mb-4" style="width: 217px; border-width: 50px; border-color: lightgray;">
			</div>
		</div>

		<!-- DESCRIPTION -->
		<div class="col-12 text-center">
			<h3 class="description mt-3 mb-3">
				We also encourage you to check out these other great hiring resources!
			</h3>

			<div class="d-inline-block">
				<a target="_blank" class="d-block my-2" style="text-decoration: none;" href="https://airtable.com/shrwkjKZZf4tSoCZo">Brown Girls Doc Mafia</a>
				<a target="_blank" class="d-block my-2" style="text-decoration: none;" href="https://www.acrossthecut.com/">Across the Cut</a>
				<a target="_blank" class="d-block my-2" style="text-decoration: none;" href="https://allianceofdoceditors.com/editor-hiring-resources/">The Alliance of Documentary Editors</a>
				<a target="_blank" class="d-block my-2" style="text-decoration: none;" href="http://blackinpost.com/staff-up/">Black in Post</a>
			</div>
		</div>
	</div>
</div>
