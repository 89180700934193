<div class="main">

    <a href="http://{{resource.link}}" target="_blank"><img  src="{{this.resource.image}}" alt="" ></a>


    <h4 class="mt-3 mb-2" style="font-size: 18px; color:#272343;" >
        {{this.resource.title}}
        
    </h4>

    <p style="color: rgb(121, 120, 120); font-size: 14px;">
       {{this.resource.subtitle}}
    </p>

    <p style=" font-size: 14px;">
        {{this.resource.description}}
    </p>

</div>