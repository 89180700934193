import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from 'src/api.client';
import { Toast } from 'src/app/utils/toast';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {

  passwordRecoveryForm: FormGroup;
  private BYTES: string;

  constructor(
    private fb: FormBuilder, 
    private apiClient: Client,
    private route: ActivatedRoute,
    private router: Router
    ) {

    this.passwordRecoveryForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, { validator: this.mustMatch('password', 'confirmPassword') })

  }


  ngOnInit(): void {
    this.route.params.subscribe( (params) => {
      this.BYTES = params.token;
    })
  }

  recoverPassword() {
    this.apiClient.reset({
        bytes: this.BYTES,
        email: this.passwordRecoveryForm.get('email').value,
        newPassword: this.passwordRecoveryForm.get('password').value
    }).subscribe( data => {
      Toast.fire({
        title: "Your password was updated.",
        icon: "success"
      });

      this.router.navigate(['/signin'])
    }, err => {
      Toast.fire({
        title: "There was a problem updating your password, please try again.",
        icon: "error"
      })
    })
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
}
