import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SignupComponent } from './components/signup/signup.component';
import { SigninComponent } from './components/signin/signin.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FooterComponent } from './components/shared/footer/footer.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { ContactComponent } from './components/contact/contact.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';
import { FilterComponent } from './components/shared/filter/filter.component';
import { CropperComponent2 } from './components/shared/cropper/cropper.component';
import { DropdownComponent } from './components/shared/dropdown/dropdown.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TokenInterceptor } from './services/token.interceptor';
import { JwtInterceptor } from './services/jwt.interceptor';
import { HomeComponent } from './components/home/home/home.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { EditorsComponent } from './components/editors/editors.component';
import { CardComponent } from './components/editors/shared/card/card.component';

import { SpinnersAngularModule } from 'spinners-angular';
import { AboutComponent } from './components/about/about.component';
import { Page404Component } from './components/page404/page404.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { ResourceCardComponent } from './components/resources/resource-card/resource-card.component';
import { EndorsersComponent } from './components/home/home/endorsers/endorsers.component';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { TitlecaseDirective } from './directive/titlecase.directive'
import { SwiperModule } from 'swiper/angular';
import { OurGuidelinesComponent } from './components/our-guidelines/our-guidelines.component';
import { PostJobComponent } from './components/post-job/post-job.component';

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    SigninComponent,
    FooterComponent,
    NavbarComponent,
    CropperComponent2,
    ContactComponent,
    ProfileFormComponent,
    FilterComponent,
    DropdownComponent,
    HomeComponent,
    ProfileComponent,
    EditorsComponent,
    CardComponent,
    AboutComponent,
    Page404Component,
    ResourcesComponent,
    ResourceCardComponent,
    EndorsersComponent,
    PasswordRecoveryComponent,
    TitlecaseDirective,
    OurGuidelinesComponent,
    PostJobComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    AngularCropperjsModule,
    SpinnersAngularModule,
    ImageCropperModule,
    SwiperModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
