<div>
  <div *ngIf="router.url === '/home'" class="d-flex justify-content-center align-items-center">
    <div  class="banner_margin text-center d-flex justify-content-center align-items-center">
      <img  src="assets/home/BDEwide.png" alt="" class="logo"> 
      <img  src="assets/logo/three_lines.png" alt="" class="logo2"> 
    </div>
  
  
  
  </div>
  
  
  <nav [ngClass]="[(router.url === '/home')? 'nav_margin' : '']" class="navbar navbar-expand-custom navbar-dark bg-white mt-4 mb-4" style="color: #272343; ">
  
    <div  class="container-fluid text-center d-flex align-items-center justify-content-center" style="width:400px ;">
  
  
      <a *ngIf="router.url != '/home'" [routerLink]="['home']"><img class=" imgStyle" src="assets/logo/three_lines.png"></a>
      <button class="navbar-toggler ml-4 btnc" type="button" data-toggle="collapse" data-target=".dual-collapse2">
        <span class="navbar-toggler-icon"></span>
      </button>
  
    </div>
  
  
    
  
  
  
    <div
      class=" justify-content-between align-items-center text-center navbar-collapse collapse w-100 order-3 dual-collapse2"
      id="collapsingNavbar2">
      <ul class="navbar-nav mx-auto text-center text-left">
        <li class="nav-item ">
          <a class="nav-link mr-4" routerLink="home" routerLinkActive="active-link">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link mr-4" routerLink="editors" routerLinkActive="active-link">Find Editors</a>
        </li>

        <li class="nav-item">
          <a class="nav-link mr-4" routerLink="post-job" routerLinkActive="active-link">Post a Job</a>
        </li>
  
        <li class="nav-item">
          <a class="nav-link mr-4" routerLink="about" routerLinkActive="active-link">About</a>
        </li>

        <li class="nav-item">
          <a class="nav-link mr-4" routerLink="guidelines" routerLinkActive="active-link">Our Guidelines</a>
        </li>
  
        <li class="nav-item">
          <a class="nav-link mr-4" routerLink="signup" *ngIf=!isLogged() routerLinkActive="active-link">Sign Up</a>
        </li>
  
        <li class="nav-item">
          <a class="nav-link mr-4" [routerLink]="'profile/'+getId()" *ngIf=isLogged()
            routerLinkActive="active-link">Profile</a>
        </li>

        <li class="nav-item">
          <a class="nav-link mr-4 mrc" routerLink="contact" routerLinkActive="active-link">Contact Us</a>
        </li>
  
      </ul>

      <ul class="nav navbar-nav d-flex justify-content-md-center  justify-content-start flex-nowrap text-center">
        <li class="nav-item mr-5 text-center">
          
          <button [ngClass]="[(router.url === '/home')? 'log_margin' : '']" type="button" class="btn-sm btn " routerLink="signin" style="width: 99px; outline-style: none;"
            *ngIf=!isLogged()>LOG IN</button>

          <button [ngClass]="[(router.url === '/home')? 'log_margin' : '']" type="button" class="btn-sm btn " routerLink="signin" (click)="logOut()"
            style="width: 99px; outline-style: none;" *ngIf=isLogged() routerLinkActive="active-link">LOG OUT</button>

        </li>

      </ul>
  
    </div>

  </nav>

</div>
