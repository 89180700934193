<div class="main">
  <div class="row margin container-fluid">
    <div class="col-lg-2 col-sm-12 col-md-12">
      <div class="row">
        <!-- -------------------------------Title--------------------------------- -->
        <div class="col-12">
          <h1 class="title_font">
            Find an <span class="h1color">Editor</span>
          </h1>
        </div>
        <!-- -------------------------------******--------------------------------- -->
      </div>

      <div class="row">
        <!-- -------------------------------Filters--------------------------------- -->
        <ul class="list-unstyled components mt-5">
          <span class="caret"></span>
          <form [formGroup]="editorsForm">
            <li>
              <a
                class="filter_font"
                href="#seeking"
                data-toggle="collapse"
                aria-expanded="false"
                >Position
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mb-1 caret"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M5 8l4 4 4-4z" />
                </svg>
              </a>
              <ul class="open collapse list-unstyled ml-2" id="seeking">
                <li class="mt-2">
                  <app-dropdown
                    [category]="'position'"
                    [formGroupCustom]="editorsForm"
                    formArrayNameCustom="position"
                    [editors]="editors"
                  ></app-dropdown>

                  <br />
                </li>
              </ul>
            </li>
            <li>
              <a
                class="filter_font"
                href="#documentary"
                data-toggle="collapse"
                aria-expanded="false"
                >Documentary Experience
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mb-1 caret"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M5 8l4 4 4-4z" />
                </svg>
              </a>
              <ul class="open collapse list-unstyled ml-2" id="documentary">
                <li class="mt-2">
                  <app-dropdown
                    [category]="'documentaryExperience'"
                    [formGroupCustom]="editorsForm"
                    formArrayNameCustom="documentaryExperience"
                    [editors]="editors"
                  ></app-dropdown>

                  <br />
                </li>
              </ul>
            </li>
            <li>
              <a
                class="filter_font"
                href="#other"
                data-toggle="collapse"
                aria-expanded="false"
                >Other experience<svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mb-1 caret"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M5 8l4 4 4-4z" />
                </svg>
              </a>
              <ul class="open collapse list-unstyled ml-2" id="other">
                <li class="mt-2">
                  <app-dropdown
                    [category]="'otherExperience'"
                    [formGroupCustom]="editorsForm"
                    formArrayNameCustom="otherExperience"
                    [editors]="editors"
                  ></app-dropdown>

                  <br />
                </li>
              </ul>
            </li>
            <li>
              <a
                class="filter_font"
                href="#based"
                data-toggle="collapse"
                aria-expanded="false"
                >Based In
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mb-1 caret"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M5 8l4 4 4-4z" /></svg
              ></a>
              <ul class="open collapse list-unstyled ml-2" id="based">
                <li class="mt-2">
                  <app-dropdown
                    [category]="'BasedIn'"
                    [formGroupCustom]="editorsForm"
                    formArrayNameCustom="BasedIn"
                    [editors]="editors"
                  ></app-dropdown>

                  <br />
                </li>
                <h4 class="text_font2">For other options, use search bar.</h4>
              </ul>
            </li>
            <li>
              <a
                class="filter_font"
                href="#languages"
                data-toggle="collapse"
                aria-expanded="false"
                >Languages spoken
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mb-1 caret"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M5 8l4 4 4-4z" /></svg
              ></a>
              <ul class="open collapse list-unstyled ml-2" id="languages">
                <li class="mt-2">
                  <app-dropdown
                    [category]="'languages'"
                    [formGroupCustom]="editorsForm"
                    formArrayNameCustom="languages"
                    [editors]="editors"
                  ></app-dropdown>

                  <br />
                </li>
                <h4 class="text_font2">For other options, use search bar.</h4>
              </ul>
            </li>
            <li>
              <a
                class="filter_font"
                href="#racial"
                data-toggle="collapse"
                aria-expanded="false"
                >Racial/cultural identity<svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mb-1 caret"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M5 8l4 4 4-4z" />
                </svg>
              </a>
              <ul class="open collapse list-unstyled ml-2" id="racial">
                <li class="mt-2">
                  <app-dropdown
                    [category]="'racial'"
                    [formGroupCustom]="editorsForm"
                    formArrayNameCustom="racial"
                    [editors]="editors"
                    [ignoreItems]="ignoreRacialItems"
                  ></app-dropdown>

                  <br />
                </li>
                <h4 class="text_font2">For other options, use search bar.</h4>
              </ul>
            </li>
            <li>
              <a
                class="filter_font"
                href="#self"
                data-toggle="collapse"
                aria-expanded="false"
                >Self Identification
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mb-1 caret"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M5 8l4 4 4-4z" /></svg
              ></a>
              <ul class="open collapse list-unstyled ml-2" id="self">
                <li class="mt-2">
                  <app-dropdown
                    [category]="'selfIdentification'"
                    [formGroupCustom]="editorsForm"
                    formArrayNameCustom="selfIdentification"
                    [editors]="editors"
                  ></app-dropdown>

                  <br />
                </li>
                <h4 class="text_font2">For other options, use search bar.</h4>
              </ul>
            </li>
            
            <li>
              <a
                class="filter_font"
                href="#activelyLooking"
                data-toggle="collapse"
                aria-expanded="false"
                >Availability
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mb-1 caret"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M5 8l4 4 4-4z" /></svg
              ></a>
              <ul class="open collapse list-unstyled ml-2" id="activelyLooking">
                <li class="mt-2">
                  <app-dropdown
                    [category]="'activelyLooking'"
                    [formGroupCustom]="editorsForm"
                    formArrayNameCustom="activelyLooking"
                    [editors]="editors"
                  ></app-dropdown>

                  <br />
                </li>
              </ul>
            </li>

          </form>
        </ul>
        <!-- -------------------------------******--------------------------------- -->
      </div>
    </div>

    <div class="col-lg-10 col-sm-12 col-md-12">
      <div class="row">
        <!-- -------------------------------Search Bar--------------------------------- -->
        <div class="col-lg-7 col-md-12 col-sm-12">
          <form [formGroup]="editorsForm">
            <div class="form-group" style="min-width: 280px">
              <label class="sr-only" for="search">Username</label>

              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text search_bar" style="border: none">
                    <svg
                      width="19"
                      height="20"
                      viewBox="0 0 19 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.7088 18.0181L14.4016 13.7109C15.4386 12.3304 15.9984 10.6501 15.9965 8.92347C15.9965 4.51327 12.4084 0.925232 7.99824 0.925232C3.58804 0.925232 0 4.51327 0 8.92347C0 13.3337 3.58804 16.9217 7.99824 16.9217C9.72482 16.9236 11.4052 16.3638 12.7856 15.3268L17.0928 19.634C17.3109 19.8289 17.5953 19.933 17.8876 19.9248C18.1799 19.9166 18.458 19.7968 18.6648 19.59C18.8716 19.3832 18.9914 19.1051 18.9996 18.8128C19.0077 18.5205 18.9037 18.2361 18.7088 18.0181ZM2.28521 8.92347C2.28521 7.79354 2.62027 6.68899 3.24803 5.74948C3.87579 4.80998 4.76804 4.07773 5.81196 3.64532C6.85588 3.21292 8.00458 3.09978 9.1128 3.32022C10.221 3.54066 11.239 4.08477 12.038 4.88375C12.8369 5.68273 13.3811 6.7007 13.6015 7.80892C13.8219 8.91713 13.7088 10.0658 13.2764 11.1098C12.844 12.1537 12.1117 13.0459 11.1722 13.6737C10.2327 14.3014 9.12817 14.6365 7.99824 14.6365C6.48361 14.6347 5.03153 14.0322 3.96053 12.9612C2.88952 11.8902 2.28703 10.4381 2.28521 8.92347Z"
                        fill="#2D334A"
                      />
                    </svg>
                  </div>
                </div>

                <input
                  type="text"
                  style="border: none"
                  formControlName="search"
                  class="form-control search_bar text_font"
                  id="search"
                  placeholder="Search by name or keyword"
                />
              </div>
            </div>
          </form>
        </div>
        <!-- -------------------------------**********--------------------------------- -->

        <!-- -------------------------------View saved editors--------------------------------- -->
        <div class="col-lg-5 col-md-12 col-sm-12">
          <div
            class="d-flex justify-content-end align-items-center button_media"
          >
            <button
              type="submit"
              class="btn btn-primary btn-lg btn-block"
              data-bs-toggle="modal"
              data-bs-target="#SavedEditors"
              style="
                width: 228px;
                background-color: rgba(52, 157, 157, 0.75);
                font-weight: bold;
                border: none;
                color: white;
              "
              (click)="viewEditors()"
            >
              View Saved Editors
            </button>
          </div>
        </div>
        <!-- -------------------------------**********--------------------------------- -->
      </div>

      <div
        class="row d-flex justify-content-center align-items-center mt-5"
        *ngIf="searchResult$ | async; else loadingOrError"
      >
        <!-- -------------------------------Cards--------------------------------- -->

        <div
          class="d-flex justify-content-center align-items-center"
          style="flex-wrap: wrap"
        >
          <app-card
            [profile]="result"
            *ngFor="
              let result of searchResult$
                | async
                | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize
            "
          ></app-card>
        </div>

        <!-- -------------------------------**********----------------------------------->
      </div>

      <ng-template #loadingOrError class="mt-5">
        <ng-container
          class="row mt-5 justify-content-center d-flex align-items-center"
          *ngIf="errorObject; else loading"
        >
          <h1 class="text_font text-center mb-5 mt-5" style="font-size: 20px">
            We couldn’t find any Editors with those filters
          </h1>
          <div class="d-flex justify-content-center align-items-center">
            <img
              class="mb-5 notFound"
              src="assets/editors/notfound.svg"
              style="width: 40%"
              alt="Not found image"
            />
          </div>
        </ng-container>

        <ng-template class="" #loading>
          <div class="container text-center">
            <h2 class="loading mb-5">Loading...</h2>
            <div>
              <sa-spinner-circular></sa-spinner-circular>
            </div>
          </div>
        </ng-template>
      </ng-template>

      <!-- -------------------------------Pagination----------------------------------->

      <div class="d-flex justify-content-center mt-3">
        <ngb-pagination
          class="pagination"
          [(page)]="page"
          collectionSize="{{ (searchResult$ | async)?.length }}"
          [pageSize]="pageSize"
        ></ngb-pagination>
      </div>

      <!-- -------------------------------**********----------------------------------->
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="SavedEditors"
  tabindex="-1"
  aria-labelledby="SavedEditorsLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
  >
    <div class="modal-content">
      <div class="row modal-header" style="border: none">
        <div class="col-12 d-flex justify-content-end align-items-center mb-3">
          <button
            type="button"
            class="btn-close"
            style="background-color: white; outline: none"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">X</span>
          </button>
        </div>

        <div
          class="
            col-lg-4 col-md-4 col-sm-12
            d-flex
            align-items-center
            savedTitle
          "
        >
          <h1 class="title_font modal-title ml-3" id="SavedEditorsLabel">
            Saved <span class="h1color">Editors</span>
          </h1>
        </div>

        <div
          class="
            col-lg-6 col-md-6 col-sm-12
            d-flex
            justify-content-end
            align-items-center
            exportButton
          "
        >
          <button
            type="button"
            id="export"
            class="btn btn-primary btn-lg mr-1"
            style="
              
              background-color: rgba(52, 157, 157, 0.75);
              border: none;
            "
            (click)="exportCsv()"
          >
            EXPORT SELECTED EDITORS
          </button>

          <button
            type="button"
            id="export"
            class="btn btn-primary btn-lg "
            
            (click)="deleteBookmarks()"
          >
            DELETE SELECTED EDITORS
          </button>
        </div>

        <div class="col-12 mt-3">
          <div
            class="
              ml-3
              form-check
              mt-3
              custom-control custom-checkbox custom-checkbox-yellow
            "
          >
            <input
              class="
                form-check-input
                mr-2
                custom-control-input custom-control-input-yellow
              "
              type="checkbox"
              id="checkAll"
              (change)="checkAll($event)"
            />
            <label
              class="form-check-label text_font2 custom-control-label"
              for="checkAll"
            >
              {{ this.check ? "Deselect" : "Select" }} All Editors
            </label>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <!-- -------------------------------Large Content row --------------------------------- -->
        <div class="laptop row">
          <div class="col">
            <h2 class="text_font" style="font-weight: 600; font-size: 16">
              Name
            </h2>
          </div>
          <div class="col">
            <h2 class="text_font" style="font-weight: 600; font-size: 16">
              Position
            </h2>
          </div>
          <div class="col">
            <h2 class="text_font" style="font-weight: 600; font-size: 16">
              Based in
            </h2>
          </div>
          <div class="col">
            <h2 class="text_font" style="font-weight: 600; font-size: 16">
              Email
            </h2>
          </div>
        </div>

        <!-- Iterar todo -->

        <div *ngFor="let bookmark of bookmarks" class="d-block">
          <!-- -------------------------------SM MD Content row --------------------------------- -->
          <div
            class="
              form-check
              custom-control custom-checkbox custom-checkbox-yellow
              ml-4
            "
          >
            <input
              class="
                form-check-input
                mr-2
                custom-control-input custom-control-input-yellow
              "
              type="checkbox"
              id="{{ bookmark._id }}"
              (change)="checkEditor($event)"
              [checked]="this.check"
            />

            <label
              class="row ml-2 mt-4 form-check-label custom-control-label"
              for="{{ bookmark._id }}"
            >
              <!-- -------------------------------Names--------------------------------- -->
              <div class="col-lg-3 col-md-12 col-sm-12 mb-4">
                <h2
                  class="text_font mobile"
                  style="font-weight: 600; font-size: 16"
                >
                  Name
                </h2>

                <div class="">
                  <label
                    class="form-check-label text_font2"
                    for="{{ bookmark._id }}"
                  >
                    {{ bookmark.Name }}
                  </label>
                </div>
              </div>

              <!-- -------------------------------Positions --------------------------------- -->
              <div class="col-lg-3 col-md-12 col-sm-12 mb-4">
                <h2
                  class="text_font mobile"
                  style="font-weight: 600; font-size: 16"
                >
                  Position
                </h2>

                <div class="">
                  <label
                    class="form-check-label text_font2"
                    for="{{ bookmark._id }}"
                  >
                    {{ bookmark.Position }}
                  </label>
                </div>
              </div>

              <!-- -------------------------------Based in --------------------------------- -->

              <div class="col-lg-3 col-md-12 col-sm-12 mb-4">
                <h2
                  class="text_font mobile"
                  style="font-weight: 600; font-size: 16"
                >
                  Based in
                </h2>

                <div class="">
                  <label
                    class="form-check-label text_font2"
                    for="{{ bookmark._id }}"
                  >
                    {{ bookmark.BasedIn }}
                  </label>
                </div>
              </div>

              <!-- -------------------------------Emails --------------------------------- -->

              <div class="col-lg-3 col-md-12 col-sm-12 mb-4">
                <h2
                  class="text_font mobile"
                  style="font-weight: 600; font-size: 16"
                >
                  Email
                </h2>

                <div class="">
                  <label
                    class="form-check-label text_font2"
                    for="{{ bookmark._id }}"
                  >
                    {{ bookmark.Email }}
                  </label>
                </div>
              </div>
            </label>
          </div>

          <!-- -------------------------------*********************** --------------------------------- -->
        </div>
      </div>
    </div>
  </div>
</div>
