import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Client } from '../../api.client';
import decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { tokenName } from '@angular/compiler';

interface Credentials {
  email: string,
  password: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  cachedRequests: Array<HttpRequest<any>> = [];

  constructor(private apiClient: Client) { }

  public logOut(): void {
    return localStorage.removeItem('token');
  }

  public login(credentials: Credentials) {
    let status = false;
    let error = '';

    return this.apiClient.signin(credentials).pipe(
      map(data => {
        localStorage.setItem('token', data.body.token);
        localStorage.setItem('_id', data.body._id);
        return data;
      })
    )

  }

  public getToken(): string {
    return localStorage.getItem('token');
  }

  public getId(): string {
    return localStorage.getItem('_id');
  }

  /**
   * Function that verfifies jwt expiration date,
   * 
   * @decodedToken Token to verify
   */
  private isTokenExpired(decodedToken: any): boolean {
    return (Math.floor((new Date).getTime() / 1000)) >= decodedToken.exp;
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    if (!token) return false;
    const decodedToken: any = decode(token);
    if (!this.isTokenExpired(decodedToken)) {
      return true;
    }
  }

  getRecoverEmail(body: any) {
    return this.apiClient.recover(body.email);
  }


}
