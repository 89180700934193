import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router';
import { Toast } from "../../utils/toast";
import { AuthService } from "../../services/auth.service";

import { Client } from '../../../api.client';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  signinForm: FormGroup;
  forgotPassword: FormGroup;
  emailSent: boolean;
  emailError: boolean;

  constructor(private fb: FormBuilder, private router: Router, private auth: AuthService, private apiClient: Client) {
    

    this.signinForm = this.fb.group({
      email: new FormControl('', [Validators.email, Validators.required]),
      password: new FormControl('', [Validators.required])
    });

    this.forgotPassword = this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    });

    this.emailSent = false;



  }

  ngOnInit(): void {
  }

  titleCaseWord(s: string) {
    if (!s) return s;
    return s.trim().split(" ").map(word => `${word[0].toUpperCase()}${word.substr(1)}`).join(" ");
  }

  async login(): Promise<any> {

    if (!this.signinForm.valid) {
      Toast.fire({
        title: "Please review your application!",
        icon: 'error'
      });
      return;
    }

    this.auth.login(this.signinForm.value).subscribe(data => {
      const id = data.body._id;
      this.router.navigate(['/profile/' + id]);
    }, error => {
      Toast.fire({
        title: 'Please verify your email or password.',
        icon: 'error'
      });
    })





  }

  getEmailPassword() {
    if (!this.forgotPassword.invalid) {
      this.auth.getRecoverEmail(this.forgotPassword.value).subscribe(r => {
        Toast.fire({
          title: 'Verification token sent',
          text: 'We sent a verification link to your email.',
          icon: 'success'
        });
        this.forgotPassword.reset()
      }, e => {
        console.log(e)
        Toast.fire({
          title: 'Error',
          text: 'We could not restore your password, please try again.',
          icon: 'error'
        });
        this.forgotPassword.reset()

      }
      )
    }
    return;
  }


}
