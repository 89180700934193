import { ConditionalExpr } from '@angular/compiler';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from 'src/api.client'
import { BookmarksService } from 'src/app/services/bookmarks.service';


@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {



  @Input()
  profile: any;
  goToTRUE: boolean = true;
  goToFALSE: boolean = false;
  constructor(private router: Router, private bookmarkService: BookmarksService) { }


  ngOnInit(): void { }



  goToProfile(b: boolean) {

    

    if(b === true){
      window.open(`https://bipocdoceditors.com/profile/${this.profile._id}`, "_blank");
      return
    }
    
    return false;
        
  }
  /*
  capitalize(s: string): string {

    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  */
  addBookmark(): void {
    this.bookmarkService.addBookmark({
      _id: this.profile._id,
      Name: `${this.profile.user.name} ${this.profile.user.lastName}`,
      BasedIn: this.profile.BasedIn.join(', '),
      Position: this.profile.position.join(', '),
      Email: this.profile.contactEmail || '',
      Link: `${'https://bipocdoceditors.com/profile/'}${this.profile._id}`


    });

  }

  removeBookmark(): void {
    this.bookmarkService.removeBookmark(this.profile._id);
  }

  findInBookmarks(): Boolean {
    return this.bookmarkService.findBookmark(this.profile._id);
  }

  displayText(type: string) {
    if (type == 'location') {
      if (this.profile.BasedIn.length == 0) return '';
      if (this.profile.BasedIn.length == 1) return '' + this.profile.BasedIn[0];
      if (this.profile.BasedIn.length == 2) return '' + this.profile.BasedIn[0] + ' ( +' + (this.profile.BasedIn.length - 1) + ' other)';
      return '' + this.profile.BasedIn[0] + ' ( +' + (this.profile.BasedIn.length - 1) + ' others)';
    } else {
      if (this.profile.position.length == 0) return '';
      if (this.profile.position.length == 1) return '' + this.profile.position[0];
      if (this.profile.position.length == 2) return '' + this.profile.position[0] + ' ( +' + (this.profile.position.length - 1) + ' other)';
      return '' + this.profile.position[0] + ' ( +' + (this.profile.position.length - 1) + ' others)';
    }

  }

}
