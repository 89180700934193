import { keyframes } from '@angular/animations';
import { literalArr } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { Profile } from './bookmarks.service';

@Injectable({
  providedIn: 'root'
})

export class CsvDataService {
  static exportToCsv(filename: string, rows: object[]) {
    if (!rows || !rows.length ) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    keys.shift();

    

    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {  
          let cell = row[k] === null || row[k] === undefined || k ==="_id"   ? '' : row[k] ;
          
         
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}