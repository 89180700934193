
<div class="main">	
	<!-- TOP PART (TITLE & DESCRIPTION) -->
	<div class="row d-flex justify-content-center mb-5">

		<!-- TITLE -->
		<div class="col-12">
			<h1 class="text-center mb-2 resp_title" style="font-weight: 550; font-size: 48px; color:#272343;">OUR GUIDELINES
			</h1>
			<!-- Underline -->
			<div class="d-flex justify-content-center">
				<hr class="mb-4" style="width: 217px; border-width: 50px; border-color: lightgray;">
			</div>
		</div>

		<!-- DESCRIPTION -->
		<div class="col-12">
			<p class="description">
				A cornerstone of our work is the <strong>Associate Editor Initiative.</strong> Join us in calling for a mid-tier junior editor position
				with a focus on creative and professional development, mentorship, and support.
			</p>
		</div>
	</div>

	<!-- /TOP PART (TITLE & DESCRIPTION) -->

	<!-- Associate Editor Initiative-->
	<div class="row mb-5 mt-5">
		<div class="row align-items-between guidelines justify-content-center d-flex ">
			<h1 class="col-lg-6 col-md-6 col-sm-12 resp-subtitles"
				style="color:#272343; font-size: 48px; font-weight: bold;">ASSOCIATE EDITOR <span class="resp-subtitles"
					style="color: #FFB803; font-size: 48px;">INITIATIVE</span></h1>
			<div class="btn-primary col-lg-6 col-md-6 col-sm-6 justify-content-center d-flex align-items-center" style="background-color: #e3f6f5;">
				<a class="bg-warning text-decoration-none h5 p-3" style="color:#272343; "
				href="assets/about/AssociateEditorInitiative.pdf" download (click)="downloadResource()">Download Editor Initiative</a>
			</div>
		</div>


		<div class="row guidelines-content">
			<!-- First point -->
			<p class="mt-3">
				<img src="assets/about/Vector.png" alt="" class="mr-2" style="width: 20px;">
				Re-think the roles in the edit room. The main path to becoming a full editor is by working as an AE, but
				this ladder is broken. The work of an AE is increasingly only technical and far removed from the
				creative work and conversations between editor and director. The promotion from AE to editor is very
				difficult and often involves personal connections or networks that disproportionally exclude BIPOC
				editors. When building your edit room, think about including an “Associate Editor” role in between
				assistant and full editor. This is a creative junior editor / apprentice position that provides the
				crucial missing rung in the edit room. Work to hire emerging BIPOC talent for this role. An Associate
				Editor should be creatively involved in the edit and should work directly with the full editor (and
				occasionally the director), creating selects, doing early scene work, and exchanging creative ideas.
				This position will also build personal networks with veteran editors, directors and producers that lead
				to experience and promotion. This type of Associate Editor role is already happening in some productions
				and with great success, and with coordinated efforts can become standard across the industry.
			</p>

		</div>

	</div>

	<!-- /Associate Editor Initiative-->

	<!-- GUIDELINES FOR CHANGE -->
	
	<div class="row my-5">
	
		<div class="row align-items-between guidelines justify-content-center d-flex mb-3">
			<h1 class="col-lg-6 col-md-6 col-sm-12 resp-subtitles"
				style="color:#272343; font-size: 48px; font-weight: bold;">GUIDELINES FOR <span class="resp-subtitles"
					style="color: #FFB803; font-size: 48px;">CHANGE</span></h1>
			<div class="btn-primary col-lg-6 col-md-6 col-sm-6 justify-content-center d-flex align-items-center" style="background-color: #e3f6f5;">
				<a class="bg-warning text-decoration-none h5 p-3" style="color:#272343;" href="assets/about/GuidelinesForChange.pdf" download (click)="downloadResource()">Download guidelines</a>
			</div>
		</div>
	
	
		<div class="row guidelines-content">
			<!-- First point -->
			<!-- 
		            <p>
		                 <img src="assets/about/Vector.png" alt="" class="mr-2" style="width: 20px;">
		                <b class="b">Re-think the assistant editor’s role.</b>
		                The main path to becoming a full editor is by working as an AE,
		                but
		                this ladder is broken. The work of an AE is increasingly only technical and far removed from the
		                creative
		                work and conversations between editor and director. The promotion from AE to editor is very difficult
		                and
		                often involves personal connections or networks that disproportionally exclude BIPOC editors.
		            </p>
		            <p>
		                When building your edit room, think about including an “Associate Editor” role in between assistant and
		                full
		                editor -- a junior editor / apprentice position that provides the crucial missing rung in the edit room.
		                Work to hire emerging BIPOC talent for this role. An Associate Editor should be creatively involved in
		                the
		                edit and should work directly with the full editor (and occasionally the director), creating selects,
		                doing
		                early scene work, and exchanging creative ideas.
		            </p>
		
		            <p>
		                This position will also build personal networks with veteran editors, directors and producers that lead
		                to
		                experience and promotion. This type of Associate Editor role is already happening in some productions
		                and
		                with great success, and with coordinated efforts can become standard across the industry.
		            </p>
		            -->
			<!-- /First point -->
	
			<!-- Second point -->
			<p>
				<img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 20px;">
				<b class="b">Hire and recommend BIPOC editors.</b> Focus on looking outside your existing
				social/professional networks so
				you can expand them.
			</p>
			<!-- /Second point -->
	
			<!-- Third point -->
			<p>
				<img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 20px;">
				<b class="b">Don’t pigeonhole BIPOC editors</b> by hiring editors for projects that only relate to their
				racial identity
				or experience.
			</p>
			<!-- /Third point -->
	
			<!-- Fourth point -->
			<p>
				<img src="assets/about/Vector.png " class="mr-2" alt="" style="width: 20px;">
				<b class="b">Mentor</b> emerging BIPOC talent.
			</p>
			<!-- /Fourth point -->
	
			<!-- Fifth point -->
			<p>
				<img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 20px;">
				For BIPOC and non-BIPOC editors alike,<b> build your schedule around sustainable and humane working
					hours
					for editors and AEs.</b> The typical work day for a documentary editor is 8-10 hours a day with
				weekends
				off. Longer working hours do not mean more work gets done, and often longer hours means the work suffers
				creatively as editors burn out.
			</p>
			<!-- /Fifth point -->

			<!-- Sixth point -->
			<p>
				<img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 20px;">
				There’s a difference between inclusion and tokenism. What’s the difference? Power. It’s not enough to say that “X number
				of new hires should be people of color” unless the people you’re hiring will be able to influence the work in a
				meaningful way. <strong>
					This initiative promotes hiring BIPOC editors in a way that shares power and empowers</strong>, shrinks disparities, and leads to more equitable outcomes. And better films!
			</p>
			<!-- Sixth point -->	
		</div>
	</div>
	<!-- /GUIDELINES FOR CHANGE -->

	<div class="row mb-5 mt-5">
		<div class="row align-items-between guidelines justify-content-center d-flex ">
			<h1 class="col-lg-6 col-md-6 col-sm-12 resp-subtitles"
				style="color:#272343; font-size: 48px; font-weight: bold;">ADE GUIDE FOR DOCUMENTARY <span class="resp-subtitles" style="color: #FFB803; font-size: 48px;">SCHEDULES</span>
			</h1>
			<div class="btn-primary col-lg-6 col-md-6 col-sm-6 justify-content-center d-flex align-items-center" style="background-color: #e3f6f5;">
				<a target="_blank" class="bg-warning text-decoration-none h5 p-3" style="color:#272343; "
				href="https://allianceofdoceditors.com/about/ade_scheduling_guide/">Download Guide</a>
			</div>
		</div>


		<div class="row guidelines-content">
			<!-- First point -->
			<p class="mt-3">
				<img src="assets/about/Vector.png" alt="" class="mr-2" style="width: 20px;">
				The Alliance of Documentary Editors' "Guide for Documentary Schedules" is an industry-leading call for sustainable,
				humane schedules and work hours for documentary post-production. The guide is based on data from hundreds of
				professional editors and assistant editors, with decades of experience working in documentary film and television.
			</p>
		</div>
	</div>

	<!-- TOP PART (TITLE & DESCRIPTION) -->
	<div class="row d-flex justify-content-center mb-5">

		<!-- TITLE -->
		<div class="col-12">
			<h1 class="text-center mb-2 resp_title" style="font-weight: 550; font-size: 48px; color:#272343;">COMMUNITY RESOURCES
			</h1>
			<!-- Underline -->
			<div class="d-flex justify-content-center">
				<hr class="mb-4" style="width: 217px; border-width: 50px; border-color: lightgray;">
			</div>
		</div>

		<!-- DESCRIPTION -->
		<div class="col-12">
			<p class="description">
				We believe we are stronger when we stand together in collective action. We encourage you to check out
				these documentary community resources and support initiatives that seek to break down barriers for
				filmmakers who are BIPOC, living with disabilities, undocumented, female, non-binary, LGBTQ+, and from
				other
				marginalized communities.
			</p>
		</div>
	</div>

	<!-- /TOP PART (TITLE & DESCRIPTION) -->

	<div class="d-flex justify-content-center cards" style="flex-wrap: wrap;">
		<!-- CARDS -->
		<app-resource-card [resource]="resource" *ngFor="let resource of resources "></app-resource-card>
	</div>
</div>
