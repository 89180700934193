import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-endorsers',
  templateUrl: './endorsers.component.html',
  styleUrls: ['./endorsers.component.scss']
})
export class EndorsersComponent implements OnInit {

  @Input() endorser:any;
  constructor() { }

  ngOnInit(): void {
  }

}
