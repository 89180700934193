<div class="main">
    <div class="row">
        <div class="column">
            <h2 class="column__title">BIPOC Editors: Sign up to create your Editor Profile!</h2>

            <div style="text-align: center; font-size: 22px;" class="mb-2">
                Producers: No account needed! Search the database freely.
            </div>

            <div class="d-flex align-items-center justify-content-center mb-5">
                <h3 class="column__subtitle">Already have an account?</h3>
                <a class="column__subtitle" routerLink="../signin">Log in</a>
            </div>

            <form [formGroup]="signupForm" (ngSubmit)="signup()">
                <div class="row">
                    <div class="col-sm-9">
                        <div class="row">
                            <div class="col-xs-8 col-sm-6 ">
                                <div class="form-group">
                                    <label class="form__label" for="formGroupExampleInput">Editor First Name</label>
                                    <input type="text" class="form-control" id="formGroupExampleInput"
                                        formControlName="name">
                                    <div style="position: absolute;"
                                        *ngIf="signupForm.get('name').touched && signupForm.get('name').invalid">
                                        <small *ngIf="signupForm.get('name').errors"
                                            style="font-weight: 300; font-size: 15px; color: darkred; font-family: 'Montserrat', sans-serif;">
                                            Name is required</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-4 col-sm-6 ">
                                <div class="form-group">
                                    <label class="form__label" for="formGroupExampleInput2">Editor Last Name</label>
                                    <input type="text" class="form-control" id="formGroupExampleInput2"
                                        formControlName="lastName">
                                    <div style="position: absolute;"
                                        *ngIf="signupForm.get('lastName').touched && signupForm.get('lastName').invalid">
                                        <small *ngIf="signupForm.get('lastName').errors"
                                            style="font-weight: 300; font-size: 15px; color: darkred; font-family: 'Montserrat', sans-serif;">
                                            Last name is required</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-4">
                            <label class="form__label " for="formGroupExampleInput3">Email</label>
                            <input type="email" class="form-control" id="formGroupExampleInput3"
                                formControlName="email">
                            <div style="position: absolute;"
                                *ngIf="signupForm.get('email').touched && signupForm.get('email').invalid">
                                <small *ngIf="signupForm.get('email').errors"
                                    style="font-weight: 300; font-size: 15px; color: darkred; font-family: 'Montserrat', sans-serif;">
                                    Email is required</small>
                                <div *ngIf="signupForm.get('email').errors.email"> <small
                                        *ngIf="signupForm.get('email').errors"
                                        style="font-weight: 300; font-size: 15px; color: darkred; font-family: 'Montserrat', sans-serif;">
                                        Email must be a valid email address</small></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-sm-9">
                        <div class="row">
                            <div class="col-xs-8 col-sm-6">
                                <div class="form-group">
                                    <label class="form__label" for="formGroupExampleInput4">Password</label>
                                    <input type="password" class="form-control" id="formGroupExampleInput4"
                                        formControlName="password">
                                    <div style="position: absolute;"
                                        *ngIf="signupForm.get('password').touched && signupForm.get('password').invalid">
                                        <small *ngIf="signupForm.get('password').errors"
                                            style="font-weight: 300; font-size: 15px; color: darkred; font-family: 'Montserrat', sans-serif;">
                                            Password is required</small>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xs-4 col-sm-6">
                                <div class="form-group">
                                    <label class="form__label" for="formGroupExampleInput5">Confirm Password</label>
                                    <input type="password" class="form-control" id="formGroupExampleInput5"
                                        formControlName="confirmPassword">
                                    <div style="position: absolute;">
                                        <small
                                            *ngIf="signupForm.get('confirmPassword').errors && signupForm.get('confirmPassword').touched "
                                            style="font-weight: 300; font-size: 15px; color: darkred; font-family: 'Montserrat', sans-serif;">
                                            Passwords must match</small>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row mt-5">
                    <div class="form-check d-flex justify-content-center align-items-center">
                        <div>
                            <input class="form-check-input" type="checkbox" formControlName="terms">
                            <a class="column__subtitle" data-toggle="modal" data-target="#exampleModalLong" href="">I
                                agree
                                to all
                                terms and conditions.</a>
                            <div *ngIf="signupForm.get('terms').touched && signupForm.get('terms').invalid"
                                style="position: absolute;">
                                <small *ngIf="signupForm.get('terms').errors"
                                    style="font-weight: 300; font-size: 15px; color: darkred; font-family: 'Montserrat', sans-serif;">
                                    Please agree to our terms and conditions.</small>
                            </div>
                        </div>


                    </div>
                </div>

             
                
                <div class="d-flex flex-column align-items-center mt-5">
                    <div class="column__subtitle" style="width: 60%;">
                        <p >There is a one-time verification of credits upon sign up. Please allow a few days for your profile to appear online.</p>
                    </div>
                  
                    <button type="submit" style="white-space: normal;" class="btn btn-primary btn-block">CREATE EDITOR PROFILE</button>
                </div>


            </form>



        </div>

        <div class="column txt_center">

            <img class="column__image"
                src="assets/BDE2.jpeg"
                alt="First slide">
                <span class="span_credits">Credit: Kickstarter</span>
        </div>
    </div>



    <!-- Modal -->
    <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title column__subtitle" id="exampleModalLongTitle">Terms and Conditions</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body column__subtitle">
                    By creating a profile on <b>bipocdoceditors.com</b>, you consent to your profile and all information and images in the profile
                    to be shared publicly.
                </div>
            </div>
        </div>
    </div>
</div>