import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  constructor(private auth: AuthService, public router: Router) { }

  ngOnInit(): void {

  }

  getId(): string {
    if (this.isLogged()) { return this.auth.getId(); }
    return null;
  }

  isLogged(): boolean {
    return this.auth.isAuthenticated();
  }

  logOut() {
    this.auth.logOut();
    this.router.navigate(['/home']);
  }


}
