<input #imgInput type="file" (change)="fileChangeEvent($event)" hidden="true" accept="image/*" />

<button  type="button" class="btn btn-primary mt-2" (click)="imgInput.click()">{{ photoType === 'profile' ? 'add photo' : 'add poster'}}</button>




<ng-template #modalImg let-modal>
    <div class="modal-header">
        <h4 class="container_title" id="modal-basic-title">ADJUST YOUR IMAGE</h4>
    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
            [aspectRatio]="selectedRatio" format="png" (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
        <img [src]="croppedImage" class="croppedimg" />
        <div class="d-flex justify-content-center align-items-center">
                <button type="button" class="btn btn-primary mt-2"  (click)="cropImage()" >CROP</button>
        </div>

    </div>
</ng-template>