import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Client } from '../../../api.client';
import { Toast } from "../../utils/toast";
import { Router } from '@angular/router';
import { AuthService } from "../../services/auth.service";



@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup;
  termsAccepted: Boolean;

  constructor(private fb: FormBuilder, private apiClient: Client, private router: Router, private auth: AuthService) {
    this.signupForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email, Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl,
      terms: new FormControl('', Validators.requiredTrue)
    },
      { validator: this.mustMatch('password', 'confirmPassword') })
  }



  ngOnInit(): void {
  }

  async signup() {
    if (this.signupForm.valid) {
      this.apiClient.user({
        name: this.signupForm.value.name,
        lastName: this.signupForm.value.lastName,
        email: this.signupForm.value.email,
        password: this.signupForm.value.password
      }).subscribe(d => {
        this.auth.login({ email: this.signupForm.value.email, password: this.signupForm.value.password }).subscribe(data => {
          this.router.navigate(['/profileform']);
        }, error => {
          Toast.fire({
            title: 'Something went wrong!',
            icon: 'error'
          });
        })
      }, error => {
        Toast.fire({
          title: 'Something went wrong!',
          icon: 'error'
        });
      })
    }
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

}



