<!-- TITLE -->
<div class="row main py-5" style="background-color: #E3F6F5;">

    <!-- Title / info  -->
    <div class="col-lg-6 col-md-12 col-sm-12 d-flex align-items-center title-center">

        <div class="title-center">
            <h1 class="resp-title title-center" style=" color: #FFB803 ;font-size: 58px; font-weight: bold;">WEL<span
                class="resp-title" style="color: #272343; font-size: 58px;">COME</span><span 
                class="resp-title" style="color: #FFB803; font-size: 58px;">!</span>
            </h1>
            <!-- <h4 class="mt-3 subtitle" style="color: rgb(173, 172, 172); font-size: 20px;">Discover documentary
                editors easily</h4> -->
        </div>


    </div>

    <!-- Description -->
    <div class="col-lg-6 col-md-12 col-sm-12">
        <div class=" d-flex justify-content-center" style="color: #2D334A ; font-size: 17px;">
            <p>
                <b>IF YOU’RE LOOKING TO HIRE A BIPOC DOC EDITOR</b>, you’ve come to the right place! Search our database
                with customizable filters and keywords. Check out our editors’ individual profile pages with bios, contact
                info, links to IMDb credits, and resumes. <b>SAVE</b> editors as you go and then, at the end of your 
                session, <b>VIEW</b> all the editors you’ve saved. You can even <b>EXPORT EDITORS</b> to generate a spreadsheet
                of all your saved editors and their contacts.
            </p>


        </div>
        <div class=" d-flex justify-content-center mt-3" style="color: #2D334A ; font-size: 17px;">
            <p>
                <b>IF YOU’RE A BIPOC EDITOR</b> and you’d like to be in our database, we’re glad you’re here! Click <b>SIGN UP</b> and
                create your personalized profile. It’s free and there’s a simple one-time verification of credits. There
                is NO minimum credits requirement to create a profile! We just ask that you are someone who is pursuing
                doc editing as a career, whether you’re just getting started or are an industry veteran. Your profile
                page can be updated by you at any time with bios, resumes, links, and images. In your bio, we encourage
                you to write a little about yourself beyond just your editing credits. You can write about the kinds of
                films you love to watch and love to edit, your career goals, your next availability, and anything else
                you’d like to add.

            </p>


        </div>


    </div>



</div>
<!-- /TITLE -->


<div class="main">

    <!-- MIDDLE TOP -->
    <div class="row mb-5">

        <!-- Image -->
        <div class="col-lg-6 col-md-12 col-sm-12 middle-img d-flex align-items-center">
            <img src="assets/BDE1.jpg" alt="" style="width: 80%;object-fit: cover;height: 100%;">
        </div>
        <!-- /Image -->

        <div class="col-lg-6 col-md-12 col-sm-12 ">
            <div class="row">
                <div class="row align-items-between  justify-content-center d-flex ">
                    <h1 class="resp-subtitles " style="color:#272343; font-size: 48px; font-weight: bold;">FA<span
                            class="resp-subtitles " style="color: #FFB803; font-size: 48px;">QS</span></h1>

                </div>

                <div class="row my-5">
                    <div class="col-12 ">
                        <h2 class="mb-3 resp-subtitles" style="color: #272343; font-size: 36px; font-weight: bold;">What
                            does a <span class=" resp-subtitles" style="color: #FFB803; font-size: 36px;">
                                documentary</span> <span class=" resp-subtitles"
                                style="color: #272343; font-size: 36px;"> editor do?</span> </h2>
                    </div>

                    <p class="col-12" style="color: #2D334A ; font-size: 18px;">
                        The documentary editor is a storyteller. It’s a popular misconception that a documentary editor’s job is to simply 
                        “stitch together” a film according to a director’s vision. Instead, the documentary editor is the key creative 
                        force in shaping the film’s narrative arcs, character, tone, style, drama, and themes. When hiring a 
                        documentary editor, a production should be looking for a central creative collaborator, not someone to just assemble 
                        and implement an existing blueprint. 

                    </p>

                </div>

                <div class="row my-5">
                    <div class="col-12 ">
                        <h2 class="mb-3 resp-subtitles" style="color: #272343; font-size: 36px; font-weight: bold;">Why
                            are documentary <span class="resp-subtitles" style="color: #FFB803; font-size: 36px;"> edit
                            </span> <span class="resp-subtitles" style="color: #272343; font-size: 36px;"> rooms
                                overwhelmingly </span> <span class="resp-subtitles"
                                style="color: #FFB803; font-size: 36px;"> White</span><span class="resp-subtitles"
                                style="color: #272343; font-size: 36px;">? </span>
                        </h2>
                    </div>

                    <p class="col-12" style="color: #2D334A ; font-size: 18px;">
                        Relationships with directors, producers and veteran editors are the key to advancement in the
                        doc
                        industry, which almost entirely hires via personal connections and word-of-mouth. The
                        documentary
                        community is currently mostly White, and this has led to an overwhelming system of White
                        creatives
                        hiring/promoting other White creatives who are already in their circles. Without a fundamental
                        and
                        intentional change to how the edit room is set up, these systemic issues will remain.
                    </p>


                </div>

                <div class="row">
                    <div class="col-12 ">
                        <h2 class="mb-3 resp-subtitles" style="color: #FFB803; font-size: 36px; font-weight: bold;">How
                            <span class=" resp-subtitles" style="color: #272343; font-size: 36px;"> can this system
                                be</span> <span class=" resp-subtitles" style="color: #FFB803; font-size: 36px;">
                                changed?</span>
                        </h2>
                    </div>

                    <p class="col-12" style="color: #2D334A ; font-size: 18px;">
                        Systemic lasting change requires intentional and coordinated action from broadcasters,
                        production
                        companies, directors, producers, and editors.
                    </p>


                </div>
            </div>



        </div>

    </div>
    <!-- /MIDDLE TOP -->

    <!-- POSITIONS CARDS -->
    <div class="row guidelines-content mt-5">
        <div class="row align-items-start guidelines mb-4">
            <h1 class="resp-subtitles" style="color:#272343; font-size: 48px; font-weight: bold;">WHAT YOU CAN DO <span
                    class="resp-subtitles" style="color: #FFB803; font-size: 48px;"> IF YOU'RE A ... </span></h1>
        </div>

        <div class="card_format my-2">

            <div class="row">
                <!-- Title -->
                <div class="col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center">

                    <div>
                        <h2 class="text-center mb-3 resp-subtitles"
                            style="font-weight: 550; font-size: 36px; color:#272343;">STREAMER / BROADCASTER</h2>
                        <div class="d-flex justify-content-center">

                            <!-- Underline -->
                            <hr class="mb-4" style="width: 217px; border-width: 10px; border-color: lightgray;">

                        </div>
                    </div>


                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <p class="my-2">
                        <img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 20px;">
                        Promote the priority of an inclusive edit room from the top. Encourage budgets that allow for
                        sustainable hours/schedules and include an “Associate Editor” role.
                    </p>
                    <p class="my-2">
                        <img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 20px;">
                        Don’t require an editor to have a previous credit with your company
                    </p>
                </div>


            </div>




        </div>

        <div class="card_format my-2">
            <div class="row">
                <!-- Title -->
                <div class="col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center">

                    <div>
                        <h2 class="text-center mb-3 resp-subtitles"
                            style="font-weight: 550; font-size: 36px; color:#272343;">PRODUCTION COMPANY
                        </h2>
                        <div class="d-flex justify-content-center">

                            <!-- Underline -->
                            <hr class="mb-4" style="width: 217px; border-width: 10px; border-color: lightgray;">

                        </div>
                    </div>


                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <p class="my-2">
                        <img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 20px;">
                        Pledge to hire BIPOC editors and when unable to, hire BIPOC Associate Editors and Assistant
                        Editors and
                        provide tangible support, engagement and experience so those talents can rise.

                    </p>
                    <p class="my-2">
                        <img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 20px;">
                        Don’t set up unpaid internships that disproportionally exclude BIPOC talent.
                    </p>
                </div>


            </div>
        </div>

        <div class="card_format my-2">
            <div class="row">
                <!-- Title -->
                <div class="col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center">

                    <div>
                        <h2 class="text-center mb-3 resp-subtitles"
                            style="font-weight: 550; font-size: 36px; color:#272343;">DIRECTOR</h2>
                        <div class="d-flex justify-content-center">

                            <!-- Underline -->
                            <hr class="mb-4" style="width: 217px; border-width: 10px; border-color: lightgray;">

                        </div>
                    </div>


                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <p class="my-2">
                        <img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 20px;">
                        Encourage BIPOC hiring in the edit room.

                    </p>
                    <p class="my-2">
                        <img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 20px;">
                        Create places where Associate and Assistant Editors can be in the room to see how the creative
                        decisions
                        are made and how directors and editors work together.
                        Get to know and give facetime to the AEs to expand your networks and theirs.

                    </p>
                </div>


            </div>

        </div>

        <div class="card_format my-2">
            <div class="row">
                <!-- Title -->
                <div class="col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center">

                    <div>
                        <h2 class="text-center mb-3 resp-subtitles"
                            style="font-weight: 550; font-size: 36px; color:#272343;">PRODUCER</h2>
                        <div class="d-flex justify-content-center">

                            <!-- Underline -->
                            <hr class="mb-4" style="width: 217px; border-width: 10px; border-color: lightgray;">

                        </div>
                    </div>


                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <p class="my-2">
                        <img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 20px;">
                        Build your budget to include sustainable hours and an Associate Editor role.

                    </p>
                    <p class="my-2">
                        <img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 20px;">
                        Push production companies and directors to think outside their old networks.

                    </p>
                    <p class="my-2">
                        <img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 20px;">
                        Ask AEs what their goals are upon hiring and promote those goals with the team.

                    </p>
                </div>


            </div>

        </div>

        <div class="card_format my-2">
            <div class="row">
                <!-- Title -->
                <div class="col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center">

                    <div>
                        <h2 class="text-center mb-3 resp-subtitles"
                            style="font-weight: 550; font-size: 36px; color:#272343;">EDITOR</h2>
                        <div class="d-flex justify-content-center">

                            <!-- Underline -->
                            <hr class="mb-4" style="width: 217px; border-width: 10px; border-color: lightgray;">

                        </div>
                    </div>


                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                    <p class="my-2">
                        <img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 20px;">
                        Encourage BIPOC hiring and recommend BIPOC editors.

                    </p>
                    <p class="my-2">
                        <img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 20px;">
                        Include Associate Editors in creative conversations and provide creative opportunities like
                        cutting scenes and mentorship to emerging talent.
                    </p>
                </div>


            </div>

        </div>

    </div>
    <!-- /POSITIONS CARDS -->

     
</div>

<!-- CoderAmogh BANNER -->
<!-- <div class ="row mt-4 py-4" style="background-color: #148EC3; ">
    <div class="col-sm-6 d-flex justify-content-center align-items-center ">
        <h2 class="text-center mb-3 resp-subtitles"
        style="font-weight: 450; font-size: 50px !important; color:#272343;">Maintained by</h2>
    </div>
    <div class="col-sm-6 d-flex justify-content-center">
        <img class="img img-fluid" src="assets/about/coderamogh.png">
    </div>
    <div class="col-12 d-flex justify-content-center ">
        <h3 class="text-center mb-3 resp-email"
        style="font-weight: 350; font-size: 25px; color:#2D334A;">me@coderamogh.com</h3>
    </div>
    <div class="col-12 d-flex justify-content-center">
        <a href ="" target="_blank"><button type="submit" class=" btn btn-primary btn-lg btn-block"
            style="width:200px; height: 35px; background-color: rgba(52, 157, 157, 0.75);  border:none; font-size: 15px;">Visit
            Site</button></a>
    </div>
</div> -->
<!--/ CoderAmogh BANNER -->

<!-- <div class ="row mt-4 py-4" style="background-color: #E3F6F5; ">
    <div class="col-12 d-flex justify-content-center ">
        <img src="assets/about/QuantcomLogo.png"  alt="" style="width: 139px; height: 67px;">
    </div>
    <div class="col-12 d-flex justify-content-center ">
        <h2 class="text-center mb-3 resp-subtitles"
        style="font-weight: 450; font-size: 36px; color:#272343;">Developed by <b>QUANTCOM</b></h2>
    </div>
    <div class="col-12 d-flex justify-content-center ">
        <h3 class="text-center mb-3 resp-email"
        style="font-weight: 350; font-size: 25px; color:#2D334A;">team@quantcom.io</h3>
    </div>
    <div class="col-12 d-flex justify-content-center">
        <a href ="" target="_blank"><button type="submit" class=" btn btn-primary btn-lg btn-block"
            style="width:200px; height: 35px; background-color: rgba(52, 157, 157, 0.75);  border:none; font-size: 15px;">Visit
            Site</button></a>
    </div>
</div>
 -->

<div class="row mt-4 py-4" style="background-color: #148EC3; ">
    <div class="col-12 d-flex justify-content-center text-center ">
        <h2 class="my-3 resp-subtitles" style="font-weight: 450; color:#272343;">Website maintained
            by</h2>
    </div>
    <div class="col-12 d-flex justify-content-center ">
        <img class="img-fluid" src="assets/about/coderamogh2.png" alt="CoderAmogh Logo" style="width: 259px; height: 162px;">
    </div>
    <div class="col-12 d-flex justify-content-center ">
        <span class="text-center mb-3 resp-email" style="font-weight: 350; color:#2D334A;">
            <a href="mailto:contact@coderamogh.com" class="p-2" style="background-color: #FFB803; text-decoration: none;" >contact@coderamogh.com</a>
        </span>
    </div>
    <!-- <div class="col-12 d-flex justify-content-center">
        <a href="" target="_blank"><button type="submit" class=" btn btn-primary btn-lg btn-block"
                style="width:200px; height: 35px; background-color: rgba(52, 157, 157, 0.75);  border:none; font-size: 15px;">Visit
                Site</button></a>
    </div> -->
</div>
