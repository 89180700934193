import { Component, OnInit, Input } from '@angular/core';
import { filters } from '../../../utils/filters'
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ignoreElements } from 'rxjs/operators';


type categories = "position" | "otherExperience" | "documentaryExperience" | "BasedIn" | "languages" | "selfIdentification" | "racial" | "activelyLooking";

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})


export class DropdownComponent implements OnInit {
  @Input() category: categories;
  @Input() formGroupCustom: FormGroup;
  @Input() formArrayNameCustom: String;
  @Input() editors: String;
  @Input() ignoreItems: Array<String> = [];

  title: String;
  values: string[];
  renderHTML: boolean = false;

  ngOnInit(): void {
    this.getCategory()
  }

  isEditor(): Boolean {
    if (this.editors === "editors") return true;
  }

  /**
   * Function to populate dropdowns on load
   */
  getCategory() {
    filters.filter(f => {
      if (String(f.category) === this.category) {
        this.title = f.categoryDisplay;
        this.values = f.values.filter(v => !this.ignoreItems.includes(v));
        this.renderHTML = f?.renderHTML ?? false;
      }
    })
  }

   get arrayFb(): FormArray {
    return this.formGroupCustom.get(this.category) as FormArray;
  }

  includesValue(value: string): boolean {
    return this.arrayFb.value.includes(value);
  }


  getValue(event) {
    let name = event.target.value
    if (event.target.checked) {
      if(!this.includesValue(name)){
      this.arrayFb.push(new FormControl(name));
      }
    }
    else {
      let i: number = 0;

      this.arrayFb.controls.forEach(control => {

        if (name === control.value) {
          this.arrayFb.removeAt(i);
          return;
        }
        i++;
      });
      this.displayText();

    }


  }

  selectAll(event) {
    if (event.target.checked) {
      this.clearArray(this.arrayFb);
      for (var i = 0; i < this.values.length; i++) {
        this.arrayFb.push(new FormControl(this.values[i]))
      }
    } else {
      this.clearArray(this.arrayFb);
    }

  }



  clearArray = (array: FormArray) => {
    while (array.length !== 0) {
      array.removeAt(0)
    }
  }

  displayText() {
    if (this.arrayFb.value.length == 0) return '';
    if (this.arrayFb.value.length == 1) return '' + this.arrayFb.value[0];
    if (this.arrayFb.value.length == 2) return '' + this.arrayFb.value[0] +', '+ this.arrayFb.value[1] ;
    return '' + this.arrayFb.value[0] +', '+ this.arrayFb.value[1]+ '  +' + (this.arrayFb.value.length -2) + ' others';
  }

  




}
