import { Directive, ElementRef, HostListener, OnInit, AfterViewChecked } from '@angular/core';
@Directive({
  selector: '[appTitlecase]'
})
export class TitlecaseDirective implements AfterViewChecked {


  elemRef: ElementRef;
  constructor(private el: ElementRef) {
    this.elemRef = el;
  }

  ngAfterViewChecked() {
    this.onBlur();
  }

  @HostListener('blur') onBlur() {
    this.elemRef.nativeElement.value = this.toTitleCase(this.elemRef.nativeElement.value);
  }

  toTitleCase(input) {
    return input.replace(/\w\S*/g, (txt => txt[0].toUpperCase() + txt.substr(1).toLowerCase()));
  }



}
