import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinksService {

  constructor() { }

  validateURL(link: any) {
    if (!link) return;
    if (link.indexOf("http://") == 0) {
      var newLink = link.replace('http://', '');
      return newLink;
    } else if (link.indexOf("https://") == 0) {
      var newLink = link.replace('https://', '');
      return newLink;
    } else {
      return link;
    }
  }


}
