<div class="container-fluid mt-5 mb-5">
  <div class="row">

    <div class="col-lg-7 col-sm-0  text-center  img">
      <img class="imgStyle" src="assets/login/login.png" alt="">

    </div>

    <div class="col-lg-3 col-md-12  login d-flex align-items-center justify-content-center">

      <div>
        <div class="text-center">
          <h2 class="font">Welcome Back</h2>
          <h6 class="title_margin font">Log into your account</h6>
        </div>


        <form [formGroup]="signinForm" (ngSubmit)="login()">
          <div class="form-group ">
            <input formControlName="email" type="email" id="inputEmail" class="form-control"
              placeholder="E-mail Address" />
            <div style="position: absolute;" *ngIf="signinForm.get('email').touched && signinForm.get('email').invalid">

              <div *ngIf="signinForm.get('email').errors"
                style="font-weight: 300; font-size: 15px; color: darkred; font-family: 'Montserrat', sans-serif;"> Email
                must be a
                valid email Address</div>
            </div>

            <label for="inputEmail"></label>
          </div>

          <div class="form-group mb-5">
            <input formControlName="password" type="password" id="inputPassword" class="form-control"
              placeholder="Password" />
            <div style="position: absolute;"
              *ngIf="signinForm.get('password').touched && signinForm.get('password').invalid">
              <small *ngIf="signinForm.get('password').errors"
                style="font-weight: 300; font-size: 15px; color: darkred; font-family: 'Montserrat', sans-serif;">Password
                is
                required</small>

            </div>
          </div>
          <button type="submit" class="btn btn-primary btn-lg btn-block">LOG IN</button>
        </form>



        <div class="d-flex mt-3 text-center align-items-center justify-content-center">
          <h6 class="mr-3 mb-0 font">Don’t have an account yet?</h6>
          <a class="font" style="font-weight:bold;" routerLink="../signup">Sign Up</a>


        </div>
        <div class=" title_margin d-flex  text-center justify-content-center">
          <a href="#" data-target="#forgotPassword"
            style="font-size: 13px; color: gray; font-family: 'Poppins', sans-serif;" data-toggle="modal">Forgot my
            password</a>
        </div>



      </div>

    </div>
  </div>
</div>

<!-- Forgot password modal -->
<div id="forgotPassword" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header ">
        <h1 class="text-center mx-auto font header my-auto" style="font-size:20px; ">Reset your password</h1>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="panel panel-default">
            <div class="panel-body">
              <div class="text-center">

                <p class="description mt-2">Please enter your email address.</p>

                <form class="panel-body text text-center d-flex align-items-center justify-content-center"
                  [formGroup]="forgotPassword">
                  <fieldset>
                    <div class="form-group mt-2 mb-4">
                      <input class="form-control input-lg description" placeholder="E-mail Address" name="email"
                        type="email" formControlName="email" />
                      <div *ngIf="forgotPassword.get('email').touched && forgotPassword.get('email').invalid"
                        class="invalid-feedback ">
                        <small *ngIf="forgotPassword.get('email').errors"
                          style="font-weight: 300; font-size: 15px; color: darkred; font-family: 'Montserrat', sans-serif;">
                          Email is required</small>
                        <div *ngIf="forgotPassword.get('email').errors.email"> Email must be a valid email Address</div>
                      </div>
                    </div>

                    <input class="btn btn-lg btn-primary btn-block btn-warning" type="button" value="Submit"
                      (click)="getEmailPassword()">
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>