import { Component, Input, OnInit, TemplateRef, ViewChild, Output } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from '@angular/core';
import { Toast } from '../../../utils/toast';
import { Client, ImageType} from '../../../../api.client';


export interface Photo {
  id: string,
  photoType: ImageType,
  imageData: File
}


@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
})
export class CropperComponent2 implements OnInit {
  croppedImageBlob: Blob;

  constructor(private modalService: NgbModal,private apiClient:Client) {}

  @Input() photoType: ImageType;
  @Input() id: string;
  @Output() imageSaved = new EventEmitter<boolean>();
  @Output() croppedImageData = new EventEmitter<Photo>();
  selectedRatio: number = 1 / 1;

  @ViewChild('modalImg')
  public modalImgRef: TemplateRef<any>;

  ngOnInit(): void {
    if(this.photoType!='profile'){
      this.selectedRatio=11/16
    }
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileToReturn: any;
  fileName: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.fileName = event.target.files[0].name;
   this.modalService.open(this.modalImgRef);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.fileToReturn = this.base64ToFile(event.base64, this.fileName);
  }

  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  /**
   * Crops image and emits the photo as a Photo object 
   */
  cropImage() {
    this.croppedImageData.emit({
      id: this.id,
      photoType: this.photoType,
      imageData: this.fileToReturn
    });

    this.modalService.dismissAll();
  }

  uploadImage() { 
      this.apiClient
        .photo(this.id, this.photoType, {
          data: this.fileToReturn,
          fileName: 'image-upload',
        })
        .subscribe(
          (r) => {
                    this.imageSaved.emit(true);
             Toast.fire({
       title: 'Image uploaded!',
       icon: 'success',
     });
          },
            (e) => {
              Toast.fire({
                title: 'Something went wrong!',
                icon: 'error',
              });
              console.log(e);
            }
        );
      this.modalService.dismissAll();
    
  }


  imageLoaded() {
}
  cropperReady() {
}
  loadImageFailed() {
     Toast.fire({
       title: 'Image load failed',
       icon: 'error',
     });
  }
}