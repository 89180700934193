<div class="container-fluid main">
  <div>

    <div class="row">

      <!-- --------------------------------------------------    PERSONAL INFO    ---------------------------------------------------------------->

      <div class="container-fluid col-lg-4 col-sm-12  mt-2 ">

        <div class="container-fluid card_format py-4 px-0 " style="height: 100%; border-radius: 15px;">



          <div class="row  pb-4 pt-1 d-flex justify-content-center align-items-center ">

            <div class="col-lg-12 text-center " *ngIf="(profimg !== null)">
              <img class="cover_format pp_format" src="{{profimg}}" alt="Profile image needed">
            </div>

          </div>

          <div class="row d-flex justify-content-center align-items-center">

            <!-- Personal Info -->
            <div class="col-lg-9 ">

              <!-- Nombre -->
              <div class="text-center py-2">
                <h2 class="name_font" > {{name}} {{lastname}} </h2>

                <!-- Ubicación -->
                <h3 class="location_font">{{ubi2}}</h3>
              </div>

              <div class="text-center py-2" style="font-size: 1rem;" *ngIf="(activelyLooking !== 0)">
                <!-- Actively Looking -->
                <span style="color: rgb(0, 201, 110);">●</span> Actively Looking
              </div>

              <div class="col-lg-12 d-flex justify-content-center" *ngIf="!isEditable() && isContact()">

                <a type="button" data-toggle="modal" href="" (click)="openVerticallyCentered(content)"
                  class=" mt-3 mb-3  btn btn-primary  btn-block" style="width:225px;"> Contact
                  Editor</a>


              </div>
              <!---------------------------------------------------- lINKS --------------------------------------------------------->


              <div class="row mt-4">
                <div *ngIf="imdb" class="col-lg-12 col-md-6 col-sm-12  d-flex  align-items-center p-3  centered_links ">


                  <div class="link_icons rounded-circle mr-2 d-flex justify-content-center align-items-center "
                    style="background-color:#E3F6F5; ">

                    <svg width="41" height="26" viewBox="0 0 41 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0 0.303589V25.0793H40.8889V0.303589H0ZM2.55556 3.05644H38.3333V22.3264H2.55556V3.05644ZM6.38889 6.75628V18.6266H8.94444V6.75491L6.38889 6.75628ZM10.3423 6.75628V18.6266H12.7778V12.0032L13.9354 18.628H15.5723L16.6111 12.0032V18.628H18.9277V6.75628H15.3333L14.6944 12.4327L13.8153 6.75628H10.3423ZM20.3243 6.75628V18.4559H23.3987C25.0611 18.4559 25.6859 18.1806 26.1944 17.7677C26.703 17.4924 26.9534 16.8207 26.9534 16.1325V8.9916C26.9534 8.16437 26.703 7.45964 26.1944 7.18573C25.5556 6.91044 25.3153 6.75491 23.3987 6.75491L20.3243 6.75628ZM28.2312 6.92696V18.6266H30.5466C30.5466 18.6266 30.6564 17.7994 30.7868 17.9384C31.0411 17.9384 32.0748 18.4545 32.5833 18.4545C33.2222 18.4545 33.4778 18.4655 33.8611 18.3265C34.3697 18.0512 34.5 17.6658 34.5 17.2502V10.368C34.5 9.4059 33.5928 8.69154 32.8223 8.69154C32.0543 8.69154 31.3209 9.23385 31.0653 9.50914V6.92696H28.2312ZM23 8.56215C23.5086 8.56215 24.0388 8.57317 24.0388 9.12098V16.0031C24.0388 16.5523 23.3846 16.5633 23 16.5633V8.56215ZM31.4257 9.93858C31.5547 9.93858 31.7042 10.0831 31.7042 10.4974V16.3899C31.7042 16.6652 31.6799 16.9501 31.4257 16.9501C31.2953 16.9501 31.1854 16.8042 31.1854 16.3899V10.4988C31.1854 10.2235 31.1714 9.93858 31.4257 9.93858Z"
                        fill="#2D334A" />
                    </svg>


                  </div>
                  <a class="text_font2 imdb_padding" href="//{{imdb}}" target="_blank"
                    style="font-size: medium; text-decoration: underline; font-weight: 600;">IMDB
                    Link</a>
                </div>

                <div *ngIf="website"
                  class="col-lg-12 col-md-6 col-sm-12  d-flex  align-items-center p-3 centered_links">


                  <div class="link_icons rounded-circle mr-2 d-flex justify-content-center align-items-center"
                    style="background-color:#E3F6F5; ">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M17.5 8.75C17.1685 8.75 16.8505 8.8817 16.6161 9.11612C16.3817 9.35054 16.25 9.66848 16.25 10V20C16.25 20.3315 16.3817 20.6495 16.6161 20.8839C16.8505 21.1183 17.1685 21.25 17.5 21.25H22.5C22.8315 21.25 23.1495 21.1183 23.3839 20.8839C23.6183 20.6495 23.75 20.3315 23.75 20V10C23.75 9.66848 23.6183 9.35054 23.3839 9.11612C23.1495 8.8817 22.8315 8.75 22.5 8.75H17.5ZM21.25 11.25H18.75V18.75H21.25V11.25Z"
                          fill="#2D334A" />
                        <path
                          d="M7.5 8.75C7.16848 8.75 6.85054 8.8817 6.61612 9.11612C6.3817 9.35054 6.25 9.66848 6.25 10C6.25 10.3315 6.3817 10.6495 6.61612 10.8839C6.85054 11.1183 7.16848 11.25 7.5 11.25H12.5C12.8315 11.25 13.1495 11.1183 13.3839 10.8839C13.6183 10.6495 13.75 10.3315 13.75 10C13.75 9.66848 13.6183 9.35054 13.3839 9.11612C13.1495 8.8817 12.8315 8.75 12.5 8.75H7.5Z"
                          fill="#2D334A" />
                        <path
                          d="M7.5 13.75C7.16848 13.75 6.85054 13.8817 6.61612 14.1161C6.3817 14.3505 6.25 14.6685 6.25 15C6.25 15.3315 6.3817 15.6495 6.61612 15.8839C6.85054 16.1183 7.16848 16.25 7.5 16.25H12.5C12.8315 16.25 13.1495 16.1183 13.3839 15.8839C13.6183 15.6495 13.75 15.3315 13.75 15C13.75 14.6685 13.6183 14.3505 13.3839 14.1161C13.1495 13.8817 12.8315 13.75 12.5 13.75H7.5Z"
                          fill="#2D334A" />
                        <path
                          d="M6.25 20C6.25 19.6685 6.3817 19.3505 6.61612 19.1161C6.85054 18.8817 7.16848 18.75 7.5 18.75H12.5C12.8315 18.75 13.1495 18.8817 13.3839 19.1161C13.6183 19.3505 13.75 19.6685 13.75 20C13.75 20.3315 13.6183 20.6495 13.3839 20.8839C13.1495 21.1183 12.8315 21.25 12.5 21.25H7.5C7.16848 21.25 6.85054 21.1183 6.61612 20.8839C6.3817 20.6495 6.25 20.3315 6.25 20Z"
                          fill="#2D334A" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5 3.75C4.00544 3.75 3.05161 4.14509 2.34835 4.84835C1.64509 5.55161 1.25 6.50544 1.25 7.5V22.5C1.25 23.4946 1.64509 24.4484 2.34835 25.1517C3.05161 25.8549 4.00544 26.25 5 26.25H25C25.9946 26.25 26.9484 25.8549 27.6516 25.1517C28.3549 24.4484 28.75 23.4946 28.75 22.5V7.5C28.75 6.50544 28.3549 5.55161 27.6516 4.84835C26.9484 4.14509 25.9946 3.75 25 3.75H5ZM25 6.25H5C4.66848 6.25 4.35054 6.3817 4.11612 6.61612C3.8817 6.85054 3.75 7.16848 3.75 7.5V22.5C3.75 22.8315 3.8817 23.1495 4.11612 23.3839C4.35054 23.6183 4.66848 23.75 5 23.75H25C25.3315 23.75 25.6495 23.6183 25.8839 23.3839C26.1183 23.1495 26.25 22.8315 26.25 22.5V7.5C26.25 7.16848 26.1183 6.85054 25.8839 6.61612C25.6495 6.3817 25.3315 6.25 25 6.25Z"
                          fill="#2D334A" />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="30" height="30" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                  </div>
                  <a class="text_font2" href="//{{website}}" target="_blank"
                    style="font-size: medium; text-decoration: underline; font-weight: 600;">Website
                    Link</a>

                </div>


                <div *ngIf="linkedin"
                  class="col-lg-12 col-md-6 col-sm-12  d-flex  align-items-center p-3 centered_links">


                  <div class="link_icons rounded-circle mr-2 d-flex justify-content-center align-items-center"
                    style="background-color:#E3F6F5; ">
                    <svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M28.8 0.331909H1.2C0.53625 0.331909 0 0.80406 0 1.38847V25.6894C0 26.2738 0.53625 26.7459 1.2 26.7459H28.8C29.4637 26.7459 30 26.2738 30 25.6894V1.38847C30 0.80406 29.4637 0.331909 28.8 0.331909ZM8.89875 22.8399H4.4475V10.2339H8.89875V22.8399ZM6.675 8.51035C6.16472 8.51035 5.66591 8.37712 5.24163 8.12751C4.81735 7.8779 4.48666 7.52313 4.29139 7.10805C4.09612 6.69296 4.04502 6.23622 4.14457 5.79557C4.24412 5.35492 4.48985 4.95016 4.85066 4.63247C5.21148 4.31478 5.6712 4.09843 6.17167 4.01078C6.67214 3.92313 7.19089 3.96812 7.66232 4.14005C8.13376 4.31198 8.5367 4.60314 8.82019 4.97671C9.10369 5.35027 9.255 5.78946 9.255 6.23874C9.25125 7.49341 8.09625 8.51035 6.675 8.51035ZM25.5637 22.8399H21.1162V16.7086C21.1162 15.2459 21.0862 13.3672 18.8025 13.3672C16.4887 13.3672 16.1325 14.9587 16.1325 16.6029V22.8399H11.6888V10.2339H15.9562V11.9574H16.0162C16.6087 10.9668 18.06 9.92019 20.2275 9.92019C24.735 9.92019 25.5637 12.5319 25.5637 15.9261V22.8399Z"
                        fill="#2D334A" />
                    </svg>

                  </div>

                  <a class="text_font2" href="//{{linkedin}}" target="_blank"
                    style="font-size: medium; text-decoration: underline; font-weight: 600;">LinkedIn
                    Link</a>
                </div>


                <div *ngIf="customlink"
                  class="col-lg-12 col-md-6 col-sm-12  d-flex  align-items-center p-3 centered_links">


                  <div class="link_icons rounded-circle mr-2 d-flex justify-content-center align-items-center"
                    style="background-color:#E3F6F5; ">
                    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M29.25 4.875H9.75C8.45707 4.875 7.21709 5.38861 6.30285 6.30285C5.38861 7.21709 4.875 8.45707 4.875 9.75V29.25C4.875 30.5429 5.38861 31.7829 6.30285 32.6971C7.21709 33.6114 8.45707 34.125 9.75 34.125H29.25C30.5429 34.125 31.7829 33.6114 32.6971 32.6971C33.6114 31.7829 34.125 30.5429 34.125 29.25V9.75C34.125 8.45707 33.6114 7.21709 32.6971 6.30285C31.7829 5.38861 30.5429 4.875 29.25 4.875ZM19.5 9.75C19.8214 9.75 20.1356 9.8453 20.4028 10.0239C20.67 10.2024 20.8783 10.4562 21.0013 10.7531C21.1243 11.0501 21.1565 11.3768 21.0938 11.692C21.0311 12.0072 20.8763 12.2968 20.649 12.524C20.4218 12.7513 20.1322 12.9061 19.817 12.9688C19.5018 13.0315 19.1751 12.9993 18.8781 12.8763C18.5812 12.7533 18.3274 12.545 18.1489 12.2778C17.9703 12.0106 17.875 11.6964 17.875 11.375C17.875 10.944 18.0462 10.5307 18.351 10.226C18.6557 9.92121 19.069 9.75 19.5 9.75V9.75ZM13 9.75C13.3214 9.75 13.6356 9.8453 13.9028 10.0239C14.17 10.2024 14.3783 10.4562 14.5013 10.7531C14.6243 11.0501 14.6565 11.3768 14.5938 11.692C14.5311 12.0072 14.3763 12.2968 14.149 12.524C13.9218 12.7513 13.6322 12.9061 13.317 12.9688C13.0018 13.0315 12.6751 12.9993 12.3781 12.8763C12.0812 12.7533 11.8274 12.545 11.6489 12.2778C11.4703 12.0106 11.375 11.6964 11.375 11.375C11.375 10.944 11.5462 10.5307 11.851 10.226C12.1557 9.92121 12.569 9.75 13 9.75V9.75ZM30.875 29.25C30.875 29.681 30.7038 30.0943 30.399 30.399C30.0943 30.7038 29.681 30.875 29.25 30.875H9.75C9.31902 30.875 8.9057 30.7038 8.60095 30.399C8.29621 30.0943 8.125 29.681 8.125 29.25V17.875H30.875V29.25Z"
                        fill="#2D334A" />
                    </svg>
                  </div>

                  <a class="text_font2" href="//{{customlink}}" target="_blank"
                    style="font-size: medium; text-decoration: underline; font-weight: 600;">Custom
                    Link</a>


                </div>
              </div>



              <!---------------------------------------------------- ***** --------------------------------------------------------->

              <!---------------------------------------------------- PREFERRED PRONOUNS --------------------------------------------------------->

              <div *ngIf="pron" class="row  mt-4">

                <div class="col-12 ">

                  <div class="badge_media">
                    <h3 class="text_font2 mb-3" style="font-size: medium; font-weight: 600;">
                      Preferred Pronouns</h3>
                    <div class=" d-flex justify-content-start wrap">

                      <span class="badge  badge_format">{{pron}} </span>

                    </div>
                  </div>


                </div>


              </div>

              <!---------------------------------------------------- ***** --------------------------------------------------------->

              <!---------------------------------------------------- LANGUAGES --------------------------------------------------------->

              <div *ngIf="languages?.length > 0" class="row  mt-4">

                <div class="col-12 ">

                  <div class="badge_media">
                    <h3 class="text_font2 mb-3" style="font-size: medium; font-weight: 600;">
                      Languages</h3>
                    <div class=" d-flex justify-content-start wrap">

                      <div *ngFor="let lan of languages">
                        <div>
                          <span class="badge  badge_format">{{lan}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>


              <!---------------------------------------------------- ***** --------------------------------------------------------->

              <!---------------------------------------------------- IDENTITIES --------------------------------------------------------->

              <div *ngIf="ident?.length > 0" class="row  mt-4">

                <div class="col-12 ">

                  <div class="badge_media">
                    <h3 class="text_font2 mb-3" style="font-size: medium; font-weight: 600;">
                      Racial/Cultural Identities</h3>

                    <div class=" d-flex justify-content-start wrap">

                      <div *ngFor="let iden of ident">
                        <div>
                          <span class="badge  badge_format">{{iden}}</span>
                        </div>
                      </div>


                    </div>
                  </div>


                </div>


              </div>


              <!---------------------------------------------------- ***** --------------------------------------------------------->

              <div class="row mt-5">

                <div class="col-12">

                  <div class="badge_media">
                    <h3 class="text_font d-flex justify-content-start  align-items-center share_editor"
                      style="font-weight: bold;" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="height:20px;">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M13 14h-2a8.999 8.999 0 0 0-7.968 4.81A10.136 10.136 0 0 1 3 18C3 12.477 7.477 8 13 8V3l10 8-10 8v-5z" />
                      </svg>
                      Share Profile
                    </h3>
                  </div>


                </div>


              </div>

            </div>
          </div>


        </div>

      </div>


      <!-- --------------------------------------------------    BIOGRAPHY    ---------------------------------------------------------------->

      <div class="col-lg-8 col-sm-12 mt-4 ">
        <div class="container-fluid  py-4">

          <div class="row d-flex justify-content-center align-items-between">

            <!-- First name -->
            <div class="col-lg-8 col-sm-12">
              <div class="d-flex align-items-center justifu-content-between wrap name_responsive">
                <h2 class="mr-3 title_font" style="font-weight: 600; ">
                  {{name}}</h2>
                <h2 class="title_font" style="font-weight: 600; ">
                  {{lastname}}</h2>
              </div>

            </div>

            <!-- Bookmark -->
            <div *ngIf=isEditable() class="col-lg-3 col-md-12 col-sm-12 d-flex align-items-center ">
              <div class=" d-flex justify-content-end align-items-center button_media centered_links"
                style="height:100%; width:100%;">

                <button type="submit" class=" btn btn-primary btn-lg btn-block"
                  style="width: 100%; background-color: rgba(52, 157, 157, 0.75);  border:none; "
                  (click)="profileForm()">Edit Profile</button>
              </div>
            </div>


            <div *ngIf=!isEditable() class="col-lg-3 col-md-12 col-sm-12 d-block align-items-center ">
              <div class=" d-flex justify-content-end align-items-center button_media" style="height:100%; width:100%;">


                <button type=" submit" class=" btn btn-primary btn-lg btn-block"
                  style="width: 100%; background-color: rgba(52, 157, 157, 0.75);  border:none; "
                  (click)="addBookmark()" *ngIf="!findInBookmarks()">Save
                  Editor</button>

                <button type=" submit" class=" btn btn-primary btn-lg btn-block"
                  style="width: 100%; background-color: rgba(52, 157, 157, 0.75);  border:none; "
                  (click)="removeBookmark()" *ngIf="findInBookmarks()">Unsave
                  Editor</button>

              </div>

            </div>



            <div class="col-lg-11 col-sm-12">

              <div class="d-flex align-items-center justify-content-start wrap">

                <div *ngFor="let pos of position">
                  <div>
                    <span class="badge  badge_format2 mr-2">{{pos}}</span>


                  </div>
                </div>



              </div>

            </div>

          </div>

         



          <!-- POSTERS -->

          <div class="d-flex justify-content-center align-items-center">
            
          <div class="row justify-content-between mt-2">
            <div class="col-lg-4 col-sm-8 col-md-8 mt-5">
              <div *ngIf="reflink1" class="d-flex justify-content-center align-items-center single-content">
                <img class="cover_format imgStyle " src="{{poster1}}" alt="">

                <div class="row button-content d-flex justify-content-center align-items-center">

                  <a href="//{{reflink1}}" target="_blank"><button type="submit"
                      class="col-12 btn btn-primary btn-lg btn-block"
                      style="width:150px; height: 28px; background-color: #c4c4c48a; font-weight: bold; border:none; color:black;">Go
                      to link</button></a>

                </div>
              </div>

              <div *ngIf="!reflink1" class="d-flex justify-content-center align-items-center">
                <img class="cover_format imgStyle" src="{{poster1}}" alt="">
              </div>
              <div class="mt-2 text_font row d-flex justify-content-center align-items-center "  style="font-weight: bold; min-height:21px; text-align: center;">
                {{title1}}
              </div>

              <div class=" text_font row d-flex justify-content-center align-items-center" *ngIf="this.credits3">
                {{credits1}}
              </div>
            </div>


            <div class="col-lg-4 col-sm-8 col-md-8 mt-5">
              <div *ngIf="reflink2" class="d-flex justify-content-center align-items-center single-content">
                <img class="cover_format imgStyle " src="{{poster2}}" alt="">

                <div class="row button-content d-flex justify-content-center align-items-center">

                  <a href="//{{reflink2}}" target="_blank"><button type="submit"
                      class="col-12 btn btn-primary btn-lg btn-block"
                      style="width:150px; height: 28px; background-color: #c4c4c48a; font-weight: bold; border:none; color:black;">Go
                      to link</button></a>

                </div>
              </div>

              <div *ngIf="!reflink2" class="d-flex justify-content-center align-items-center">
                <img class="cover_format imgStyle" src="{{poster2}}" alt="">
              </div>

              <div class="mt-2 text_font row d-flex justify-content-center align-items-center"  style="font-weight: bold; min-height:21px; text-align: center;">
                {{title2}}
              </div>

              <div class=" text_font row d-flex justify-content-center align-items-center" *ngIf="this.credits3">
                {{credits2}}
              </div>
            </div>

            <div class="col-lg-4 col-sm-8 col-md-8 mt-5">
              <div *ngIf="reflink3" class="d-flex justify-content-center align-items-center single-content">
                <img class="cover_format imgStyle " src="{{poster3}}" alt="">

                <div class="row button-content d-flex justify-content-center align-items-center">

                  <a href="//{{reflink3}}" target="_blank"><button type="submit"
                      class="col-12 btn btn-primary btn-lg btn-block"
                      style="width:150px; height: 28px; background-color: #c4c4c48a; font-weight: bold; border:none; color:black;">Go
                      to link</button></a>

                </div>
              </div>

              <div *ngIf="!reflink3" class="d-flex justify-content-center align-items-center">
                <img class="cover_format imgStyle" src="{{poster3}}" alt="">
              </div>
              <div class="mt-2 text_font row d-flex justify-content-center align-items-center"  style="font-weight: bold; min-height: 21px; text-align: center;">
                {{title3}}
              </div>

              <div class=" text_font row d-flex justify-content-center align-items-center" *ngIf="this.credits3">
                {{credits3}}
              </div>
            </div>
          </div>

            
          </div>

              




          <!-- POSTERS -->

          <div class="row mt-5 d-flex justify-content-center align-items-center">
            <div class="col-lg-11 col-sm-12">

              <p class="paragraph">
                {{biography}}
              </p>

            </div>
          </div>

          <!-- ---------------------------------------------------------------------------------------------------------------------------->






          <!--------------------------------------------------POSTERS------------------------------------------------------------------------>


          <!-------------------------------------------------------------------------------------------------------------------------->
          <!-- -------------------------------------------------- DOWNLOAD PDF ---------------------------------------------------------------->

          <div class="row mt-5 d-flex justify-content-center align-items-center" *ngIf="isResume()">

            <div class="col-lg-11 col-sm-12">

              <div class=" d-flex justify-content-start align-items-center ">

                <a href="{{resume}}" download="resume.pdf"><button type="submit"
                    class="col-12 btn btn-primary btn-lg btn-block"
                    style="width: 100%; background-color: rgba(52, 157, 157, 0.75);  border:none;">
                    Download Resume</button></a>






              </div>

            </div>

          </div>

          <!-- ---------------------------------------------------------------------------------------------------------------------------->
          <!-- -------------------------------------------------- CREDITS ----------------------------------------------------------------

                    <div class="row mt-5 d-flex justify-content-center align-items-center">

                        <div class="col-lg-11 col-sm-12">
                            <h3>Credits</h3>

                            <p class="paragraph">

                                {{credits}}

                            </p>

                        </div>
                    </div>

                    -- ---------------------------------------------------------------------------------------------------------------------------->


        </div>

      </div>

    </div>

  </div>


</div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title name_font" style="font-size: 20px;" id="exampleModalLabel">Share Editor</h5>

        <button type="button" class="close" aria-label="Close" style="background-color: white; outline: none;"
          data-bs-dismiss="modal">
          <span aria-hidden="true">X</span>
        </button>
      </div>
      <div class="modal-body">
        <h3 class="text_font d-flex justify-content-center  align-items-center "
          style="font-weight: bold; font-size: 17px;">Social media</h3>
        <!-- Content -->
        <div class="d-flex justify-content-center  align-items-center p-2">

          <!-- Social media -->

          <!-- Sharingbutton Facebook -->
          <a class="resp-sharing-button__link" href="https://facebook.com/sharer/sharer.php?u={{link}}" target="_blank"
            rel="noopener" aria-label="">
            <div class="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--small">
              <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z" />
                </svg>
              </div>
            </div>
          </a>


          <!-- Sharingbutton Twitter -->
          <a class="resp-sharing-button__link"
            href="https://twitter.com/intent/tweet/?text=BIPOC%20Documentary%20Editors&amp;url={{link}}" target="_blank"
            rel="noopener" aria-label="">
            <div class="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--small">


              <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z" />
                </svg>
              </div>
            </div>
          </a>


          <!-- Sharingbutton LinkedIn -->
          <a class="resp-sharing-button__link"
            href="https://www.linkedin.com/shareArticle?mini=true&amp;url={{link}}&amp;title=BIPOC%20Documentary%20Editors&amp;summary=BIPOC%20Documentary%20Editors&amp;source=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D6uBhIonnPGQ"
            target="_blank" rel="noopener" aria-label="">
            <div class="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--small">
              <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0 2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5 4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z" />
                </svg>
              </div>
            </div>
          </a>

          <!-- Sharingbutton WhatsApp -->
          <a class="resp-sharing-button__link" [href]="sanitizeLink()" target="_blank" rel="noopener" aria-label=""
            data-action="share/whatsapp/share">
            <div class="resp-sharing-button resp-sharing-button--whatsapp resp-sharing-button--small">
              <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M20.1 3.9C17.9 1.7 15 .5 12 .5 5.8.5.7 5.6.7 11.9c0 2 .5 3.9 1.5 5.6L.6 23.4l6-1.6c1.6.9 3.5 1.3 5.4 1.3 6.3 0 11.4-5.1 11.4-11.4-.1-2.8-1.2-5.7-3.3-7.8zM12 21.4c-1.7 0-3.3-.5-4.8-1.3l-.4-.2-3.5 1 1-3.4L4 17c-1-1.5-1.4-3.2-1.4-5.1 0-5.2 4.2-9.4 9.4-9.4 2.5 0 4.9 1 6.7 2.8 1.8 1.8 2.8 4.2 2.8 6.7-.1 5.2-4.3 9.4-9.5 9.4zm5.1-7.1c-.3-.1-1.7-.9-1.9-1-.3-.1-.5-.1-.7.1-.2.3-.8 1-.9 1.1-.2.2-.3.2-.6.1s-1.2-.5-2.3-1.4c-.9-.8-1.4-1.7-1.6-2-.2-.3 0-.5.1-.6s.3-.3.4-.5c.2-.1.3-.3.4-.5.1-.2 0-.4 0-.5C10 9 9.3 7.6 9 7c-.1-.4-.4-.3-.5-.3h-.6s-.4.1-.7.3c-.3.3-1 1-1 2.4s1 2.8 1.1 3c.1.2 2 3.1 4.9 4.3.7.3 1.2.5 1.6.6.7.2 1.3.2 1.8.1.6-.1 1.7-.7 1.9-1.3.2-.7.2-1.2.2-1.3-.1-.3-.3-.4-.6-.5z" />
                </svg>
              </div>
            </div>
          </a>

          <!-- Sharingbutton Telegram -->
          <a class="resp-sharing-button__link"
            href="https://telegram.me/share/url?text=BIPOC%20Documentary%20Editors&amp;url={{link}}" target="_blank"
            rel="noopener" aria-label="">
            <div class="resp-sharing-button resp-sharing-button--telegram resp-sharing-button--small">
              <div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M.707 8.475C.275 8.64 0 9.508 0 9.508s.284.867.718 1.03l5.09 1.897 1.986 6.38a1.102 1.102 0 0 0 1.75.527l2.96-2.41a.405.405 0 0 1 .494-.013l5.34 3.87a1.1 1.1 0 0 0 1.046.135 1.1 1.1 0 0 0 .682-.803l3.91-18.795A1.102 1.102 0 0 0 22.5.075L.706 8.475z" />
                </svg>
              </div>
            </div>
          </a>

        </div>

        <div class="modal-footer row d-flex justify-content-center">

          <div class="col-12 d-flex justify-content-center">
            <h3 class="text_font d-flex justify-content-start  align-items-center mb-3 mt-2"
              style="font-weight: bold; font-size: 17px;">Copy link to clipboard</h3>
          </div>
          <div class=" d-flex justify-content-center">
            <div class=" col-9">
              <input type="text" class="form-control" value="{{link}}" #userinput>
            </div>

            <div class="col-3">
              <button (click)="copyInputMessage(userinput)"
                style="border:none; background-color: lightgray; color:black;" value="click to copy"
                class="btn btn-primary btn-lg btn-block"><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18 21H12C11.2044 21 10.4413 20.6839 9.87868 20.1213C9.31607 19.5587 9 18.7956 9 18V12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9H18C18.7956 9 19.5587 9.31607 20.1213 9.87868C20.6839 10.4413 21 11.2044 21 12V18C21 18.7956 20.6839 19.5587 20.1213 20.1213C19.5587 20.6839 18.7956 21 18 21ZM12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V18C11 18.2652 11.1054 18.5196 11.2929 18.7071C11.4804 18.8946 11.7348 19 12 19H18C18.2652 19 18.5196 18.8946 18.7071 18.7071C18.8946 18.5196 19 18.2652 19 18V12C19 11.7348 18.8946 11.4804 18.7071 11.2929C18.5196 11.1054 18.2652 11 18 11H12Z"
                    fill="black" />
                  <path
                    d="M9.73 15H5.67C4.96268 14.9974 4.28509 14.7152 3.78494 14.2151C3.28478 13.7149 3.00263 13.0373 3 12.33V5.67C3.00263 4.96268 3.28478 4.28509 3.78494 3.78494C4.28509 3.28478 4.96268 3.00263 5.67 3H12.33C13.0373 3.00263 13.7149 3.28478 14.2151 3.78494C14.7152 4.28509 14.9974 4.96268 15 5.67V9.4H13V5.67C13 5.49231 12.9294 5.32189 12.8038 5.19624C12.6781 5.07059 12.5077 5 12.33 5H5.67C5.49231 5 5.32189 5.07059 5.19624 5.19624C5.07059 5.32189 5 5.49231 5 5.67V12.33C5 12.5077 5.07059 12.6781 5.19624 12.8038C5.32189 12.9294 5.49231 13 5.67 13H9.73V15Z"
                    fill="black" />
                </svg>
              </button>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title name_font" style="font-size: 20px;" id="exampleModalLabel">Contact Editor</h5>
    <button type="button" class="close_button" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">X</span>

    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body">
      <div class="col-12 d-flex justify-content-center">
        <h3 class="text_font d-flex justify-content-start  align-items-center mb-4"
          style="font-weight: bold; font-size: 17px;">Copy email to clipboard</h3>
      </div>
      <div class=" d-flex justify-content-center">
        <div class=" col-9">
          <input type="text" class="form-control" value="{{mail}}" #mailInput>
        </div>

        <div class="col-3">
          <button (click)="copyInputMessage(mailInput)" style="border:none; background-color: lightgray; color:black;"
            value="click to copy" class="btn btn-primary btn-lg btn-block"><svg width="24" height="24"
              viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18 21H12C11.2044 21 10.4413 20.6839 9.87868 20.1213C9.31607 19.5587 9 18.7956 9 18V12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9H18C18.7956 9 19.5587 9.31607 20.1213 9.87868C20.6839 10.4413 21 11.2044 21 12V18C21 18.7956 20.6839 19.5587 20.1213 20.1213C19.5587 20.6839 18.7956 21 18 21ZM12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V18C11 18.2652 11.1054 18.5196 11.2929 18.7071C11.4804 18.8946 11.7348 19 12 19H18C18.2652 19 18.5196 18.8946 18.7071 18.7071C18.8946 18.5196 19 18.2652 19 18V12C19 11.7348 18.8946 11.4804 18.7071 11.2929C18.5196 11.1054 18.2652 11 18 11H12Z"
                fill="black" />
              <path
                d="M9.73 15H5.67C4.96268 14.9974 4.28509 14.7152 3.78494 14.2151C3.28478 13.7149 3.00263 13.0373 3 12.33V5.67C3.00263 4.96268 3.28478 4.28509 3.78494 3.78494C4.28509 3.28478 4.96268 3.00263 5.67 3H12.33C13.0373 3.00263 13.7149 3.28478 14.2151 3.78494C14.7152 4.28509 14.9974 4.96268 15 5.67V9.4H13V5.67C13 5.49231 12.9294 5.32189 12.8038 5.19624C12.6781 5.07059 12.5077 5 12.33 5H5.67C5.49231 5 5.32189 5.07059 5.19624 5.19624C5.07059 5.32189 5 5.49231 5 5.67V12.33C5 12.5077 5.07059 12.6781 5.19624 12.8038C5.32189 12.9294 5.49231 13 5.67 13H9.73V15Z"
                fill="black" />
            </svg>
          </button>
        </div>
      </div>

    </div>

    <div class="modal-footer d-flex justify-content-center">
      <div class="col-7">
        <a type="button" href="mailto:{{mail}}"
          style="border:none; background-color: lightgray; color:black; font-size:15px;"
          class="btn btn-primary btn-lg btn-block">
          Email them now
        </a>
      </div>


    </div>

  </div>
</ng-template>
