import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Toast } from 'src/app/utils/toast';
import { Client } from '../../../api.client';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contact: FormGroup;

  constructor(private fb: FormBuilder, private apiClient: Client) {
    this.contact = fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      message: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(600)]],
    });
  }

  ngOnInit(): void {
  }



  contactf(): any {

    if (!this.contact.valid) {
      Toast.fire({
        title: "Please review your application",
        icon: 'error'
      });
      return;
    }

    return this.apiClient.contact(this.contact.value).subscribe(d => {
      Toast.fire({
        title: `We'll get back to you!`,
        icon: 'success'
      });
      this.contact.reset();
    });

  }

}


