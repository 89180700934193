<div class="main ">

    <div class="d-flex justify-content-center">
        <img  src="{{this.endorser.image}}" alt="">
    </div>
    

    

    <!-- 
    <p style="color: rgb(121, 120, 120); font-size: 16px;">
        {{this.endorser.subtitle}} 
    </p>

    
     -->


    

</div>
