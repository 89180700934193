<div>
    <!-- GRID -->
    <!-- /GRID -->
    <div class="container">
        <div class="image-gallery">
            <a target="_blank" class="img-1" style="background-image: url('{{img1}}');">
                <i class="icon ion-md-expand"></i>
            </a>
            <a target="_blank" class="img-2" style="background-image: url('{{img2}}');">
                <i class="icon ion-md-expand"></i>
            </a>
            <a target="_blank" class="img-3" style="background-image: url('{{img3}}');">
                <i class="icon ion-md-expand"></i>
            </a>
            <a target="_blank" class="img-4 small" style="background-image: url('{{img4}}');">
                <i class="icon ion-md-expand"></i>
            </a>
            <a target="_blank" class="img-5 med-2" style="background-image: url('{{img5}}');">
                <i class="icon ion-md-expand"></i>
            </a>
            <a target="_blank" class="img-6 small" style="background-image: url('{{img6}}');">
                <i class="icon ion-md-expand"></i>
            </a>
            <a target="_blank" class="img-7 med-2" style="background-image: url('{{img7}}');">
                <i class="icon ion-md-expand"></i>
            </a>
            <a target="_blank" class="img-8 med" style="background-image: url('{{img8}}');">
                <i class="icon ion-md-expand"></i>
            </a>
            <a target="_blank" class="img-9 med " style="background-image: url('{{img9}}');">
                <i class="icon ion-md-expand"></i>
            </a>
            <a target="_blank" class="img-10 med" style="background-image: url('{{img10}}');">
                <i class="icon ion-md-expand"></i>
            </a>
            <a target="_blank" class="img-11 med" style="background-image: url('{{img11}}');">
                <i class="icon ion-md-expand"></i>
            </a>
        </div>
    </div>


    <!-- OUR MISSION -->
    <div class="row cont">
        <div class="col-12">
            <div class="d-flex justify-content-center  mt-5">
                <h1 class="text-center titles" style="color:#272343; font-size: 48px;">OUR <span class="titles"
                        style="color: #FFB803; font-size: 48px;">MISSION</span></h1>
            </div>

            <div class="d-flex justify-content-center">

                <!-- Underline -->
                <hr style="width: 217px; border-width: 2px; border-color: lightgray;">

            </div>
        </div>
        <div class="col-12 justify-content-center mt-4"
            style="text-align: justify; font-size: 16px; color: #272343; line-height: 40px; font-weight: 400;">
            <p>The current lack of inclusion and access in documentary production is a systemic problem. The documentary
                community is currently mostly White, and this has led to an overwhelming system of White creatives
                mentoring and promoting other White creatives already in their circles. Like all areas of production,
                this is very true in documentary edit rooms. Without a fundamental and intentional effort to provide
                tangible long-term support to BIPOC (Black, Indigenous, and People of Color) talent, these systemic
                issues will remain. It will take all of us to make lasting change.</p>
        </div>


        <div class="col-12  d-flex justify-content-end">
            <a style="text-align: end; font-size: 14px; color: #FFB803; line-height: 40px; font-weight: 400;"
                routerLink="../about">More about us
                <svg width="21" height="8" viewBox="0 0 21 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M20.3785 4.35355C20.5738 4.15829 20.5738 3.84171 20.3785 3.64645L17.1966 0.464466C17.0013 0.269204 16.6847 0.269204 16.4895 0.464466C16.2942 0.659728 16.2942 0.976311 16.4895 1.17157L19.3179 4L16.4895 6.82843C16.2942 7.02369 16.2942 7.34027 16.4895 7.53553C16.6847 7.7308 17.0013 7.7308 17.1966 7.53553L20.3785 4.35355ZM0 4.5H20.025V3.5H0L0 4.5Z"
                        fill="#272343" />
                </svg>
            </a>
        </div>

    </div>

    <!-- /OUR MISSION -->
    <div class="row cont">
        <div class="col-12 mb-4">
            <div class=" justify-content-center  mt-5" >
                <h1 class="text-center titles" style="color:#272343; font-size: 48px; "  >BIPOC EDITORS:
                   
                </h1>
                
            </div>
                  
            <div class="d-flex justify-content-center mb-4">
        
                <!-- Underline -->
                <hr style="width: 217px; border-width: 2px; border-color: lightgray;">
        
            </div>

            <h1 class=" text-center titles" routerLink="../signup" style="color: #FFB803; font-size: 48px; cursor: pointer;">SIGN UP
                FOR THE DATABASE HERE</h1>
        </div>
        <div class="col-12">
           
            
            <div class="d-flex justify-content-center  mt-5">
                <!-- <h1 class="text-center titles" style="color:#272343; font-size: 48px;">CALL TO ACTION:
                    </h1> -->
                    
            </div>
            <h1 class="titles text-center" style="color: #272343; font-size: 48px;">DOC PRODUCERS:</h1>

            <div class="d-flex justify-content-center mb-4">

                <!-- Underline -->
                <hr style="width: 217px; border-width: 2px; border-color: lightgray;">

            </div>
        </div>
        <div class="col row text-center justify-content-center"
            style="text-align: justify; font-size: 48px; color: #FFB803;; line-height: 40px; font-weight: 400;">
            <div class="row">
                <p class="my-2 col-lg-12 col-md-12 col-sm-12 " >
                    <img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 48px;">
                    <a style="text-decoration: none; color: #FFB803;" href="/editors">
                        FIND AN EDITOR
                    </a>
                </p>
                <p class="my-2 col-lg-12 col-md-12 col-sm-12  " >
                    <img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 48px;">
                    <a style="text-decoration: none; color: #FFB803;" href="/post-job">
                        POST A JOB
                    </a>
                </p>
            </div>
            <div class="row">
                <p class="my-2 col-lg-12 col-md-12 col-sm-12 " > 
                    <img src="assets/about/Vector.png" class="mr-2" alt="" style="width: 48px;">
                    <a style="text-decoration: none; color: #FFB803;" href="/guidelines">
                        READ OUR GUIDELINES
                    </a>
                </p>
            </div>
        </div>
        <div class="col-12 mt-4 d-flex justify-content-end">
            <a style="text-align: end; font-size: 14px; color: #FFB803; line-height: 40px; font-weight: 400;"
                routerLink="../about">More about us
                <svg width="21" height="8" viewBox="0 0 21 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M20.3785 4.35355C20.5738 4.15829 20.5738 3.84171 20.3785 3.64645L17.1966 0.464466C17.0013 0.269204 16.6847 0.269204 16.4895 0.464466C16.2942 0.659728 16.2942 0.976311 16.4895 1.17157L19.3179 4L16.4895 6.82843C16.2942 7.02369 16.2942 7.34027 16.4895 7.53553C16.6847 7.7308 17.0013 7.7308 17.1966 7.53553L20.3785 4.35355ZM0 4.5H20.025V3.5H0L0 4.5Z"
                        fill="#272343" />
                </svg>
            </a>
        </div>

    </div>

     <!-- OUR PARTNERS -->


     <div class="row ">

        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-center  mt-5">
                    <div class="d-flex justify-content-center mt-5">
                        <h1 class="text-center titles" style="color:#272343; font-size: 48px;">SUPPORTED <span class="titles"
                                style="color: #FFB803; font-size: 48px;">BY</span></h1>
                    </div>

                </div>

                <div class="d-flex justify-content-center">

                    <!-- Underline -->
                    <hr style="width: 217px; border-width: 2px; border-color: lightgray;">

                </div>
            </div>

        </div>

        <div class="row px-3 mt-5 mb-3 d-flex justify-content-center">
            <div class="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-center my-3"
                *ngFor="let partner of partners">
                <img class=" partner_img" src="{{partner.image}}">
            </div>

        </div>


    </div>
    <!-- /OUR PARTNERS -->




    <!--ENDORSERS -->
    <div class="row ">

        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-center  mt-5">
                    <div class="d-flex justify-content-center mt-5">
                        <h1 class="text-center titles" style="color:#272343; font-size: 48px;">ENDORSERS</h1>
                    </div>

                </div>

                <div class="d-flex justify-content-center">

                    <!-- Underline -->
                    <hr style="width: 217px; border-width: 2px; border-color: lightgray;">

                </div>
            </div>

        </div>

        <div class="row px-3 mt-5">
            <div class="d-flex justify-content-center cards" style="flex-wrap: wrap;">
                <app-endorsers [endorser]="endorser" *ngFor="let endorser of endorsers"></app-endorsers>

            </div>


        </div>


    </div>
    <!-- /ENDORSERS -->

    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-12 text-center">
                <a href="https://www.dctvny.org/s/firehousecinema/series/bipoc-doc-editors-on-the-big-screen-MC3KX4FP3RC5FKHH6VEUZM6QIPGQ"
                    target="_blank">
                    <img src="assets/BIPOC-on-main-screen-may-2023.png" class="img img-fluid"
                        alt="BIPOC on main screen May 2023" width="850">
                </a>
            </div>
        </div>
    </div>

    <!-- DOCUMENTARY HIGHLIGHTS -->
    <div class="row mb-5">
        <div class="col-12">
            <div class="d-flex justify-content-center  mt-5">
                <div class="d-flex justify-content-center mt-5">
                    <h1 class="text-center titles" style="color:#FFB803; font-size: 48px;">DOCUMENTARY <span
                            class="titles" style="color: #272343; font-size: 48px;">HIGHLIGHTS</span></h1>
                </div>

            </div>

            <div class="d-flex justify-content-center">

                <!-- Underline -->
                <hr style="width: 217px; border-width: 2px; border-color: lightgray;">

            </div>
        </div>
        <div class="col-12 mt-5 mb-5">
            <div class="row mx-5" style="column-gap: 2rem;">

                <!-- Highlight 1 -->
                <swiper [slidesPerView]="breakpoints()" [spaceBetween]="50" (swiper)="onSwiper($event)"
                    (slideChange)="onSlideChange()" [navigation]="true"
                    [pagination]="{ clickable:true, el: '.swiper-pagination.pagination-test'}"
                    [scrollbar]="{ draggable: true }" [centerInsufficientSlides]="true">

                    <div class="col mt-4" *ngFor="let Highlight of highlights">

                        <ng-template swiperSlide>


                            <div class="row d-flex justify-content-center align-items-center ">
                                <div *ngIf="Highlight.link" style="height: 539px; width:410px;"
                                    class="single-content d-flex justify-content-center">
                                    <img style="height: 100%; width:100%; object-fit: cover; border-radius: 10px;"
                                        src="{{Highlight.banner}}">

                                    <div class="row button-content d-flex justify-content-center">

                                        <a href="//{{verifyURL(Highlight.link)}}" target="_blank"><button type="submit"
                                                class="col-12 btn btn-primary btn-lg btn-block"
                                                style="width:150px; height: 28px; background-color: #c4c4c48a; font-weight: bold; border:none; color:black;">Go
                                                to link</button></a>

                                    </div>
                                </div>

                                <div *ngIf="!Highlight.link" class="d-flex justify-content-center align-items-center">
                                    <img class="cover_format imgStyle" src="{{poster1}}" alt="">
                                </div>


                            </div>
                            <div class="row text-center mt-3">
                                <h2 style="color: #2D334A; font-size: 24px; text-transform: capitalize; ">
                                    {{Highlight.title}}
                                </h2>
                                <h4 class="mt-2"
                                    style="color: rgb(173, 172, 172); font-size: 20px; text-transform: capitalize;">
                                    {{Highlight.info}}</h4>
                            </div>
                            <!--/ Highlight 1 -->



                        </ng-template>




                    </div>


                </swiper>




            </div>

            <div class="d-flex justify-content-center">
                <div slot="container-end" class="swiper-margin-1 swiper-pagination pagination-test"></div>
            </div>


        </div>


    </div>
    <!-- /DOCUMENTARY HIGHLIGHTS -->

    <!-- ADE -->

    <div class="row mt-5 cont" style="background-color: #E3F6F5;">

        <div class="col-12 mt-4">
            <h1 class="text-center titles" style="color: #2D334A; font-size: 48px; font-weight: 300;">
                This site is an ADE initiative
            </h1>
        </div>

        <div class="col-12 mt-3">
            <h1 class="text-center" style="font-weight:250; font-size:24px; ">
                ADE is a professional community that champions the role of editors and assistant editors in the
                documentary process while providing resources for ourselves and our collaborators.
            </h1>
        </div>

        <div class="row my-3">
            <div class="col-12 d-flex justify-content-center">
                <a href ="https://allianceofdoceditors.com" target="_blank"><button type="submit" class=" btn btn-primary btn-lg btn-block"
                    style="width:200px; height: 35px; background-color: rgba(52, 157, 157, 0.75);  border:none; font-size: 15px;">Visit
                    ADE</button></a>
            </div>
        </div>


    </div>
    <!-- /ADE -->

   




</div>