import { Component, OnInit, Input } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormsModule } from '@angular/forms';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

 
  constructor( ) {
  }

  ngOnInit(): void {
  }

}
