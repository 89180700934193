<a  style="z-index: 1; text-decoration: none;"><div class="card d-flex justify-content-center single-content m-3 " >

    <div class="row button-content d-flex justify-content-center">

        <button type="button" class="col-12 btn btn-primary btn-lg btn-block"
            style="width:150px; height: 25px; background-color: #c4c4c48a; font-weight: bold; border:none; color:black;"
            (click)="goToProfile(this.goToTRUE)">View profile</button>

        <a  onClick = "false" type="button" class="col-12 btn btn-primary btn-lg btn-block" (click)="addBookmark()  "
            *ngIf="!findInBookmarks()"
            style="width:150px; height: 25px; background-color: #c4c4c48a; font-weight: bold; border:none; color:black;  z-index: 3; text-align: center;"><div class="mt-1">Save
                editor</div></a>

        <a  onClick = "false"  type="button" class="col-12 btn btn-primary btn-lg btn-block" (click)="removeBookmark()"
            *ngIf="findInBookmarks()"
            style="width:150px; height: 25px; background-color: #c4c4c48a; font-weight: bold; border:none; color:black; z-index: 2;"><div class="mt-1">Unsave
                editor</div></a>

    </div>



    <header class="row title d-flex align-items-center">
        <div class="col-12 d-flex align-items-center justify-content-center " style="height: 36px;">
            <h4 class="name_font ">{{this.profile.user.name}}
                {{this.profile.user.lastName}}</h4>
        </div>

    </header>

    <section class="row d-flex justify-content-center ">

        <img class="img_style" [src]="this.profile.images.profileImage" alt="">

    </section>


    <footer class="row d-flex justify-content-start align-items-center mt-3 mx-2 ">

        <div class="col-12">
            <h5 class="text_font"><svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.99988 3.75H0.584883C0.307383 3.75 0.0298826 3.99 0.0673826 4.38L1.23738 11.8725C1.23738 11.8725 2.62488 6.3525 2.89488 5.025C2.97738 4.6275 3.35238 4.5 3.62988 4.5H7.49988C7.49988 4.5 6.97488 2.94 6.92238 2.7675C6.83238 2.4375 6.66738 2.25 6.33738 2.25H3.85488C3.58488 2.25 3.32988 2.4225 3.25488 2.73C3.18738 3.03 2.99988 3.75 2.99988 3.75ZM6.65988 3.75H3.65988C3.65988 3.75 3.97488 3 4.31238 3H5.90988C6.26988 3 6.65988 3.75 6.65988 3.75ZM2.00238 12.1875C1.76988 12.54 1.43238 12.75 1.05738 12.75H12.8549C13.2599 12.75 13.5449 12.5175 13.6274 12.1275C13.9574 10.485 14.8874 5.7975 14.8874 5.7975C14.9399 5.4225 14.6624 5.25 14.4224 5.25H11.9999V4.1475C11.9999 4.0275 11.8049 3.75 11.5049 3.75H8.68488C8.29488 3.75 8.03238 4.185 8.03238 4.185L7.49988 5.25H4.19238C3.95238 5.25 3.71988 5.3925 3.67488 5.625C3.67488 5.625 2.48238 10.65 2.38488 11.1225C2.33238 11.4 2.21988 11.865 2.00238 12.1875ZM11.5349 5.25H8.24988C8.24988 5.25 8.68488 4.5 9.09738 4.5H10.8149C11.3474 4.5 11.5349 5.25 11.5349 5.25Z"
                        fill="#FFB803" />
                </svg>

                {{displayText('position')}}

                <span tooltip="Actively Looking" *ngIf="profile.activelyLooking > 0" style="font-size: 15px ;color: rgb(0, 201, 110);">●</span>
            </h5>

        </div>

        <div class="col-12 mt-3">

            <h5 class="text_font"><svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path
                            d="M7.5 7.03125C8.53553 7.03125 9.375 6.19178 9.375 5.15625C9.375 4.12072 8.53553 3.28125 7.5 3.28125C6.46447 3.28125 5.625 4.12072 5.625 5.15625C5.625 6.19178 6.46447 7.03125 7.5 7.03125Z"
                            stroke="#FFB803" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M11.2499 7.03125C9.84368 10.3125 7.49993 14.0625 7.49993 14.0625C7.49993 14.0625 5.15618 10.3125 3.74993 7.03125C2.34368 3.75 4.68743 0.9375 7.49993 0.9375C10.3124 0.9375 12.6562 3.75 11.2499 7.03125Z"
                            stroke="#FFB803" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="15" height="15" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                {{displayText('location')}}
            </h5>

        </div>

    </footer>

</div></a>