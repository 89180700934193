import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { filters } from '../../utils/filters'
import { Client, User, Response, Body, Body6 } from '../../../api.client';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { BookmarksService, Profile } from 'src/app/services/bookmarks.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { CsvDataService } from 'src/app/services/csv-data.service';
import { take, finalize } from 'rxjs/operators';

interface SearchArrays {
  position: Array<string>,
  otherExperience: Array<string>,
  documentaryExperience: Array<string>,
  BasedIn: Array<string>,
  languages: Array<string>,
  selfIdentification: Array<string>,
  racial: Array<string>
}

@Component({
  selector: 'app-editors',
  templateUrl: './editors.component.html',
  styleUrls: ['./editors.component.scss']
})
export class EditorsComponent implements OnInit {


  @Output() imageSaved = new EventEmitter<boolean>();

  bookmarkCount: number;
  bookmarks: Array <Profile> = [];
  checkedBookmarks : Array<Profile> = [];

  title: String;
  values: string[];
  editorsForm: FormGroup;
  editors: String = 'editors';
  searchResult$: Observable<any>;
  searchbar: FormGroup;

  page = 1;
  pageSize = 16;
  isLoading: boolean = false;
  flag: boolean = false;
  errorObject = null;
  checkedEditors:string[] = [];

  check:boolean=false;
  kuku:boolean=false;

  ignoreRacialItems: Array<String> = ["Other (BIPOC)"];

  constructor(private fb: FormBuilder, private apiClient: Client, private router: Router, private bookmarkService: BookmarksService) {
    this.editorsForm = fb.group({
      search: [''],
      BasedIn: this.fb.array([]),
      position: this.fb.array([]),
      languages: this.fb.array([]),
      selfIdentification: this.fb.array([]),
      racial: this.fb.array([]),
      documentaryExperience: this.fb.array([]),
      otherExperience: this.fb.array([]),
      activelyLooking: this.fb.array([]),
    })
  }


  searchUser(): void {
    this.isLoading = true;
    this.errorObject = null;
    this.searchResult$ = this.apiClient.search({
      keyword: this.editorsForm.get("search").value,
      query: {
        BasedIn: this.editorsForm.get("BasedIn").value,
        documentaryExperience: this.editorsForm.get("documentaryExperience").value,
        languages: this.editorsForm.get("languages").value,
        otherExperience: this.editorsForm.get("otherExperience").value,
        position: this.editorsForm.get("position").value,
        racial: this.editorsForm.get("racial").value,
        selfIdentification: this.editorsForm.get("selfIdentification").value,
        ...(this.editorsForm.get("activelyLooking").value.length && {
          activelyLooking: -1
        })
      }

    }).pipe(
      map(data => {
      this.isLoading = false;

      for (let i = data.body.length - 1 ; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        const tmp = data.body[i];
        data.body[i] = data.body[j];
        data.body[j] = tmp;
      }

      return data.body;

    }),
      catchError(err => {
        this.errorObject = err;
        return throwError(err);
      }),
      finalize( () => {
        this.page = 1;
      })
    );

  }


  ngOnInit(): void {

    this.searchUser();
    this.onChanges();
    this.viewEditors();
    if(window.screen.width === 360){
      this.kuku=true;
    }


  }



  onChanges(): void {
    this.isLoading = true;
    this.editorsForm.valueChanges.subscribe(_value => {
      this.isLoading = false;
      this.searchUser();



    }, error => {
      if (error) {
        this.flag = true;
        this.isLoading = false;
      }
    })
  }

  clearArray = (array: FormArray) => {
    while (array.length !== 0) {
      array.removeAt(0)
    }
  }


  viewEditors():void{
    this.bookmarkCount = this.bookmarkService.listSize();
    this.bookmarks = this.bookmarkService.getBookmarks();
    console.log(this.bookmarks)
  }



  exportCsv(){


    if(this.checkedEditors.length>0 && this.bookmarks.length>0){
      for (var i = 0; i < this.bookmarkCount;i++){

        if (this.checkedEditors.find(x=> x == this.bookmarks[i]._id)){
          this.checkedBookmarks.push(this.bookmarks[i]);

        }
      }

      CsvDataService.exportToCsv('BIPOC_editors.csv', this.checkedBookmarks);
      this.checkedBookmarks=[];
    }

  }

  deleteBookmarks() {
    this.bookmarkService.removeBookmarks(this.checkedEditors);
    this.viewEditors();
  }

  checkEditor(e: any) {
    if(e.target.checked){
      this.checkedEditors.push(e.target.id);
    }else{
      this.checkedEditors.forEach(i=>{
        if (e.target.id==i){
          const i = this.checkedEditors.findIndex(i => i === e.target.id);
          this.checkedEditors.splice(i, 1);

        }
      })
    }

  }


  checkAll(e: any) {
  this.checkedEditors=[];
    if (e.target.checked) {
      this.check=true;
      for(var i=0; i<this.bookmarks.length;i++){
        this.checkedEditors.push(this.bookmarks[i]._id);
      }
    } else {
      this.check= false;
      this.checkedEditors = [];
    }
  }









}
