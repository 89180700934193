import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './components/signin/signin.component'
import { ContactComponent } from './components/contact/contact.component'
import { SignupComponent } from './components/signup/signup.component';
import { ProfileFormComponent } from './components/profile-form/profile-form.component'
import { HomeComponent } from './components/home/home/home.component';
import { ProfileComponent } from './components/profile/profile.component';
import { EditorsComponent } from './components/editors/editors.component';
import { AboutComponent } from './components/about/about.component';
import { Page404Component } from './components/page404/page404.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { OurGuidelinesComponent } from './components/our-guidelines/our-guidelines.component';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { AuthGuard } from './guards/auth.guard'
import { PreventLoggedInAccessGuard } from "./guards/prevent-logged-in-access.guard";
import { PostJobComponent } from './components/post-job/post-job.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'signin', component: SigninComponent, canActivate: [PreventLoggedInAccessGuard] },
  { path: 'signup', component: SignupComponent, canActivate: [PreventLoggedInAccessGuard] },
  { path: 'contact', component: ContactComponent },
  { path: 'editors', component: EditorsComponent },
  { path: 'about', component: AboutComponent },
  { path: 'resources', component: ResourcesComponent },
  { path: 'profileform', component: ProfileFormComponent, canActivate: [AuthGuard] },
  { path: 'profile/:id', component: ProfileComponent },
  { path: 'passwordrecovery/:token', component: PasswordRecoveryComponent },
  { path: '404', component: Page404Component },
  { path: 'guidelines', component: OurGuidelinesComponent },
  { path: 'post-job', component: PostJobComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
// https://stackoverflow.com/a/44372167
export const APP_ROUTING = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
