<div class="container-fluid main">
  <form [formGroup]="profileForm" (ngSubmit)="onSubmit(action)">
    <!-- Title -->

    <div class="row">
      <!-- Title -->
      <div class="col col-xs-12 d-flex justify-content-start title_box">
        <h1 class="mr-3 title_font" style="color: #272343" *ngIf="profileCreated">
          MY
        </h1>
        <h1 class="mr-3 title_font" style="color: #272343" *ngIf="!profileCreated">
          CREATE
        </h1>

        <h1 class="title_font" style="color: #ffb803">PROFILE</h1>
      </div>

      <!-- Buttons -->
      <div class="col col-xs-12">
        <div class="row d-flex justify-content-end align-items-center">

            <button type="submit" (click)="onSubmit('save')" class="mt-3 mr-2 btn btn-primary btn-lg btn-block" style="width: 240px;">
              Save Changes
            </button>
          </div>

       
      </div>
    </div>

    <div class="row">
      <div class="container-fluid col-lg-4 col-sm-12 mt-4">
        <div class="container-fluid card_format py-4">
          <div class="row py-4 d-flex justify-content-center align-items-center">
            <span *ngIf="!images.profile || images.profile === 'assets/profileform/default_pp.svg'" style="
                font-weight: 300;
                color: darkred;
                text-align: center;
                margin-bottom: 25px;
              ">
              (Profile image required)
            </span>

            <div class="col-lg-12 text-center">
              <img class="cover_format pp_format" [src]="images.profile" height="500" height="500"
                alt="Profile image" />
            </div>

            <!-- cropper -->

            <div class="col-lg-7 mt-3">
              <app-cropper [photoType]="'profile'" (croppedImageData)="imageWasCropped($event)" [id]="id">
              </app-cropper>
            </div>

            <div class="col-lg-7 mt-3">
              <button type="submit" (click)="deleteImage(profile)" class="mt-3 btn btn-primary ">
                Delete Photo
              </button>
            </div>
          </div>

          <div class="row d-flex justify-content-center align-items-center">
            <!-- Personal Info -->
            <div class="col-lg-9">
              <div class="form-group align-items-center py-2">
                <label class="form__label" for="contactEmail">Contact Email (Optional)</label>
                <input class="form-control text_font" formControlName="contactEmail" id="contactEmail" type="email" />
              </div>

              <div class="form-group align-items-center py-2">
                <label class="form__label">
                  Seeking Position <span style="
                      font-weight: 300;
                      color: darkred;
                    ">
                    (Required)
                  </span>
                </label>
                <app-dropdown [category]="'position'" [formGroupCustom]="this.profileForm"
                  formArrayNameCustom="position">
                </app-dropdown>
                <div style="position: absolute; margin-top: -10px;" *ngIf="
                    profileForm.get('position').invalid
                  ">
                  <small *ngIf="profileForm.get('position').errors" style="
                      font-weight: 300;
                      font-size: 15px;
                      color: darkred;
                      font-family: 'Montserrat', sans-serif;
                    ">
                    Seeking Position is required
                  </small>
                </div>
              </div>

              <div class="form-group align-items-center py-2">
                <label class="form__label" for="preferredPronouns">Preferred Pronouns (Optional)</label>
                <input class="form-control text_font" formControlName="preferredPronouns" id="preferredPronouns"
                  type="text" maxlength="50" />
              </div>

              <!-- Links! -->
              <div class="form-group align-items-center py-2" formGroupName="links">
                <label class="form__label" for="imdb">IMDB Link (Optional)</label>
                <input class="form-control text_font" formControlName="imdb" id="imdb" type="text" />
                <div style="position: absolute" *ngIf="
                    profileForm.get('links.imdb').touched &&
                    profileForm.get('links.imdb').invalid
                  ">
                  <small *ngIf="profileForm.get('links.imdb').errors" style="
                      font-weight: 300;
                      font-size: 15px;
                      color: darkred;
                      font-family: 'Montserrat', sans-serif;
                    ">
                    Please type in a valid url
                  </small>
                </div>
              </div>

              <div class="form-group align-items-center py-2" formGroupName="links">
                <label class="form__label" for="linkedin">LinkedIn Link (Optional)</label>
                <input class="form-control text_font" formControlName="linkedin" id="linkedin" type="url" />
                <div style="position: absolute" *ngIf="
                    profileForm.get('links.linkedin').touched &&
                    profileForm.get('links.linkedin').invalid
                  ">
                  <small *ngIf="profileForm.get('links.linkedin').errors" style="
                      font-weight: 300;
                      font-size: 15px;
                      color: darkred;
                      font-family: 'Montserrat', sans-serif;
                    ">
                    Please type in a valid url
                  </small>
                </div>
              </div>

              <div class="form-group align-items-center py-2" formGroupName="links">
                <label class="form__label" for="website">Website Link (Optional)</label>
                <input class="form-control text_font" formControlName="website" id="website" type="text" />
                <div style="position: absolute" *ngIf="
                    profileForm.get('links.website').touched &&
                    profileForm.get('links.website').invalid
                  ">
                  <small *ngIf="profileForm.get('links.website').errors" style="
                      font-weight: 300;
                      font-size: 15px;
                      color: darkred;
                      font-family: 'Montserrat', sans-serif;
                    ">
                    Please type in a valid url
                  </small>
                </div>
              </div>

              <div class="form-group align-items-center py-2" formGroupName="links">
                <label class="form__label" for="customLink">Custom Link (Optional)</label>
                <input class="form-control text_font" formControlName="customLink" id="customLink" type="text" />
                <div style="position: absolute" *ngIf="
                    profileForm.get('links.customLink').touched &&
                    profileForm.get('links.customLink').invalid
                  ">
                  <small *ngIf="profileForm.get('links.customLink').errors" style="
                      font-weight: 300;
                      font-size: 15px;
                      color: darkred;
                      font-family: 'Montserrat', sans-serif;
                    ">
                    Please type in a valid url
                  </small>
                </div>
              </div>

              <div class="form-group align-items-center py-2">
                <label class="form__label" for="receiveJobNotifs">Receive job notifications</label>
                <input type="checkbox" class="ml-2" id="receiveJobNotifs" formControlName="receiveJobNotifs">

                <div style="position: absolute" *ngIf="
                                  profileForm.get('receiveJobNotifs').touched &&
                                  profileForm.get('receiveJobNotifs').invalid
                                ">
                  <small *ngIf="profileForm.get('receiveJobNotifs').errors" style="
                                    font-weight: 300;
                                    font-size: 15px;
                                    color: darkred;
                                    font-family: 'Montserrat', sans-serif;
                                  ">
                    Yes or no
                  </small>
                </div>
              </div>

              <div class="form-group align-items-center py-2">
                <label class="form__label" for="activelyLooking">Actively Looking</label>
 
                <input type="checkbox" class="ml-2" id="activelyLooking" formControlName="activelyLooking">

                <div>
                  <small style="font-size: 15px; color: blue; font-family: 'Montserrat', sans-serif;">
                    {{ activelyLookingOriginalValue === 0 ? "(Disabled)" : "(Enabled till " + (activelyLookingOriginalValue | date) +
                    ")" }}
                  </small>
                </div>

                <div>
                  <small style="font-size: 13px; font-family: 'Montserrat', sans-serif;">
                    Check this box if you'd like producers to know that you are actively looking for work (i.e. you are available to start a
                    new job now or within 1-2 months.) Make sure you click "Save Changes" to your profile after adjusting this checkbox.
                    This filter will stay active for three months and then you'll get an email asking you if you want to reactivate.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-8 col-sm-12 mt-4">
        <div class="container-fluid card_format py-4">
          <div class="row d-flex justify-content-center align-items-center">
            <!-- First name -->
            <div class="col-lg-5 col-sm-12 mt-1">
              <div class="form-group">
                <label class="form__label" for="name">First name <span style="
                      font-weight: 300;
                      color: darkred;
                    ">
                    (Required)
                  </span></label>
                <input  type="text" class="form-control text_font" id="name" formControlName="name" />
                <div style="position: absolute" *ngIf="
                    profileForm.get('name').touched &&
                    profileForm.get('name').invalid
                  ">
                  <small *ngIf="profileForm.get('name').errors" style="
                      font-weight: 300;
                      font-size: 15px;
                      color: darkred;
                      font-family: 'Montserrat', sans-serif;
                    ">
                    A name is required
                  </small>
                </div>
              </div>
            </div>

            <!-- Last Name -->
            <div class="col-lg-5 col-sm-12 mt-1">
              <div class="form-group">
                <label class="form__label" for="lastName">Last name <span style="
                      font-weight: 300;
                      color: darkred;
                    ">
                    (Required)
                  </span></label>
                <input  type="text" class="form-control text_font" id="lastName"
                  formControlName="lastName" />
                <div style="position: absolute" *ngIf="
                    profileForm.get('lastName').touched &&
                    profileForm.get('lastName').invalid
                  ">
                  <small *ngIf="profileForm.get('lastName').errors" style="
                      font-weight: 300;
                      font-size: 15px;
                      color: darkred;
                      font-family: 'Montserrat', sans-serif;
                    ">
                    A lastname is required
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div class="row d-flex justify-content-center align-items-center mt-2">
            <!-- Biography -->
            <div class="col-lg-10 col-sm-12">
              <div class="form-group">
                <label class="form__label" for="bio">Biography <span style="
                      font-weight: 300;
                      color: darkred;
                    ">
                    (Required)
                  </span></label>
                <textarea class="form-control text_font" id="bio" formControlName="bio"
                  placeholder="Please tell us about yourself. Your work, your career goals, what kind of movies love you to work on, your special interests, why you’re an editor, next availability, etc&#10;&#10;Maximum 300 words"></textarea>
                <div style="position: absolute" *ngIf="
                    profileForm.get('bio').touched &&
                    profileForm.get('bio').invalid
                  ">
                   <small *ngIf="profileForm.get('bio').errors" style="
                      font-weight: 300;
                      font-size: 15px;
                      color: darkred;
                      font-family: 'Montserrat', sans-serif;
                    ">
                    Please make sure you've written less than 300
                    words.</small> 
                </div> 

              </div>
            </div>
          </div>

          <div class="row d-flex justify-content-center align-items-center">
            <div class="form-group col-lg-5 col-sm-12 mt-3">
              <label class="form__label">Documentary Experience <span style="
                    font-weight: 300;
                    color: darkred;
                  ">
                  (Required)
                </span></label>
              <app-dropdown [category]="'documentaryExperience'" [formGroupCustom]="profileForm"
                formArrayNameCustom="documentaryExperience">
              </app-dropdown>
              <div style="position: absolute; margin-top: -10px;" *ngIf="
                    profileForm.get('documentaryExperience').invalid
                  ">
                  <small *ngIf="profileForm.get('documentaryExperience').errors" style="
                      font-weight: 300;
                      font-size: 15px;
                      color: darkred;
                      font-family: 'Montserrat', sans-serif;
                    ">
                    Documentary Experience is required
                  </small>
                </div>
            </div>

            <div class="form-group col-lg-5 col-sm-12 mt-3">
              <label class="form__label">Other Experience (Optional) </label>
              <app-dropdown [category]="'otherExperience'" [formGroupCustom]="profileForm"
                formArrayNameCustom="otherExperience">
              </app-dropdown>
            </div>
          </div>

          <div class="row d-flex justify-content-center align-items-center">
            <div class="form-group col-lg-5 col-sm-12 mt-3">
              <label class="form__label">Based In (Optional)</label>
              <app-dropdown [category]="'BasedIn'" [formGroupCustom]="profileForm" formArrayNameCustom="BasedIn">
              </app-dropdown>
            </div>

            <div class="form-group col-lg-5 col-sm-12 mt-3">
              <label class="form__label">Languages (Optional) </label>
              <app-dropdown [category]="'languages'" [formGroupCustom]="profileForm" formArrayNameCustom="languages">
              </app-dropdown>
            </div>
          </div>

          <div class="row d-flex justify-content-center align-items-center">
            <div class="form-group col-lg-5 col-sm-12 mt-3">
              <label class="form__label">Racial/Cultural Identity <span style="
                      font-weight: 300;
                      color: darkred;
                    ">
                    (Required)
                  </span></label>
              <app-dropdown [category]="'racial'" [formGroupCustom]="profileForm" formArrayNameCustom="racial">
              </app-dropdown>
              <div style="position: absolute; margin-top: -10px;" *ngIf="
                    profileForm.get('racial').invalid
                  ">
                  <small *ngIf="profileForm.get('racial').errors" style="
                      font-weight: 300;
                      font-size: 15px;
                      color: darkred;
                      font-family: 'Montserrat', sans-serif;
                    ">
                    Racial/Cultural Identity is required
                  </small>
                </div>
            </div>

            <div class="form-group col-lg-5 col-sm-12 mt-3">
              <label class="form__label">Self Identification (Optional) </label>
              <app-dropdown [category]="'selfIdentification'" [formGroupCustom]="profileForm"
                formArrayNameCustom="selfIdentification">
              </app-dropdown>
            </div>
          </div>

          <div class="row" style="margin-top: 20px;">
            <div class="custom-input-file col-lg-12 col-md-6 col-sm-6 col-xs-6 btn btn-primary file">
              <input type="file" id="fichero-tarifas" accept="application/pdf" class="input-file" value=""
                (change)="onSelectFile($event)" />
              Upload Resume
            </div>
          </div>
        </div>

        <div class="container-fluid d-flex justify-content-center align-items-center card_format mt-5">
          <div class="row d-flex justify-content-center align-items-center py-4">
            <div class="container-fluid d-flex justify-content-center">
              <label class="form__label mt-2">Add Film Posters of Your Work (Optional)</label>
            </div>

            <div class="container-fluid col-lg-4 col-sm-12 col-md-12 mt-3">
              <div class="d-flex justify-content-center align-items-center">
                <img class="cover_format imgStyle" [src]="images.poster1" alt="" />
              </div>

              

              <div class="container-fluid">
                <label class="form__label mt-4" for="referenceLink">Film Title and Year</label>
              </div>

              <div formGroupName="images">
                <div formGroupName="posters">
                  <div class="form-group d-flex justify-content-center align-items-center" formGroupName="poster1">
                    <input class="col-lg-11 col-sm-12 form_label form-control text_font" id="refLink2"
                      formControlName="description" for="referenceLink" type="text" placeholder="Film Title (Year)" />
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <label class="form__label mt-2" for="credits">Your Credit</label>
              </div>

              <div formGroupName="images">
                <div formGroupName="posters">
                  <div class="form-group d-flex justify-content-center align-items-center" formGroupName="poster1">
                    <input class="col-lg-11 col-sm-12 form_label form-control text_font" id="credits"
                      formControlName="credits" for="referenceLink" type="text" />
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <label class="form__label mt-2">Add Film Link</label>
              </div>

              <div formGroupName="images">
                <div formGroupName="posters">
                  <div class="form-group d-flex justify-content-center align-items-center" formGroupName="poster1">
                    <input class="col-lg-11 col-sm-12 form_label form-control text_font" id="referenceLink"
                      formControlName="referenceLink" for="referenceLink" type="text" />
                    <div style="position: absolute" *ngIf="
                        profileForm.get('images.posters.poster1.referenceLink')
                          .touched &&
                        profileForm.get('images.posters.poster1.referenceLink')
                          .invalid
                      ">
                      <small *ngIf="
                          profileForm.get(
                            'images.posters.poster1.referenceLink'
                          ).errors
                        " style="
                          font-weight: 300;
                          font-size: 15px;
                          color: darkred;
                          font-family: 'Montserrat', sans-serif;
                        ">
                        Please type in a valid url
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row d-flex justify-content-center">
                <div class="col-lg-12 col-md-6 col-sm-6">
                  <app-cropper [photoType]="'poster1'" (croppedImageData)="imageWasCropped($event)" [id]="id">
                  </app-cropper>
                </div>
              </div>

            
                <button type="submit" (click)="deleteImage(poster1)" class="mt-3 btn btn-primary col-lg-12 col-md-6 col-sm-6">
                  Delete Poster
                </button>
              
            </div>

            

            <div class="container-fluid col-lg-4 col-sm-12 col-md-12 mt-3">
              <div class="d-flex justify-content-center align-items-center">
                <img class="cover_format imgStyle" [src]="images.poster2" alt="" />
              </div>

             

              <div class="container-fluid">
                <label class="form__label mt-4" for="referenceLink">Film Title and Year</label>
              </div>

              <div formGroupName="images">
                <div formGroupName="posters">
                  <div class="form-group d-flex justify-content-center align-items-center" formGroupName="poster2">
                    <input class="col-lg-11 col-sm-12 form_label form-control text_font" id="refLink2"
                      formControlName="description" for="referenceLink" type="text" placeholder="Film Title (Year)"/>
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <label class="form__label mt-2" for="credits2">Your Credit</label>
              </div>

              <div formGroupName="images">
                <div formGroupName="posters">
                  <div class="form-group d-flex justify-content-center align-items-center" formGroupName="poster2">
                    <input class="col-lg-11 col-sm-12 form_label form-control text_font" id="credits2"
                      formControlName="credits" for="referenceLink" type="text" />
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <label class="form__label mt-2" for="referenceLink">Add Film Link</label>
              </div>

              <div formGroupName="images">
                <div formGroupName="posters">
                  <div class="form-group d-flex justify-content-center align-items-center" formGroupName="poster2">
                    <input class="col-lg-11 col-sm-12 form_label form-control text_font" id="refLink2"
                      formControlName="referenceLink" for="referenceLink" type="text" />
                  </div>
                </div>
              </div>

              <div class="row d-flex justify-content-center">
                <div class="col-lg-12 col-md-6 col-sm-6">
                  <app-cropper [photoType]="'poster2'" (croppedImageData)="imageWasCropped($event)" [id]="id">
                  </app-cropper>
                </div>
              </div>

              <button type="submit" (click)="deleteImage(poster2)" class="mt-3 btn btn-primary col-lg-12 col-md-6 col-sm-6">
                Delete Poster
              </button>
            </div>

            <div class="container-fluid col-lg-4 col-sm-12 col-md-12 mt-3">
              <div class="d-flex justify-content-center align-items-center">
                <img class="cover_format imgStyle" [src]="images.poster3" alt="" />
              </div>

             

              <div class="container-fluid">
                <label class="form__label mt-4" for="referenceLink">Film Title and year</label>
              </div>

              <div formGroupName="images">
                <div formGroupName="posters">
                  <div class="form-group d-flex justify-content-center align-items-center" formGroupName="poster3">
                    <input class="col-lg-11 col-sm-12 form_label form-control text_font" id="refLink2"
                      formControlName="description" for="referenceLink" type="text" placeholder="Film Title (Year)"/>
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <label class="form__label mt-2" for="credits3">Your Credit</label>
              </div>

              <div formGroupName="images">
                <div formGroupName="posters">
                  <div class="form-group d-flex justify-content-center align-items-center" formGroupName="poster3">
                    <input class="col-lg-11 col-sm-12 form_label form-control text_font" id="credits3"
                      formControlName="credits" for="referenceLink" type="text" />
                  </div>
                </div>
              </div>
              <div class="container-fluid">
                <label class="form__label mt-2" for="referenceLink">Add Film Link</label>
              </div>
              <div formGroupName="images">
                <div formGroupName="posters">
                  <div class="form-group d-flex justify-content-center align-items-center" formGroupName="poster3">
                    <input class="col-lg-11 col-sm-12 form_label form-control text_font" id="refLink3"
                      formControlName="referenceLink" for="referenceLink" type="text" />
                  </div>
                </div>
              </div>


              <div class="row d-flex justify-content-center">
                <div class="col-lg-12 col-md-6 col-sm-6">
                  <app-cropper [photoType]="'poster3'" (croppedImageData)="imageWasCropped($event)" [id]="id">
                  </app-cropper>
                </div>
                <div class="col-lg-12 col-md-6 col-sm-6">
                  <button type="submit" (click)="deleteImage(poster3)" class="mt-3 btn btn-primary ">
                    Delete Poster
                  </button>
                </div>
                
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>    
  </form>

  <div class="row justify-content-center align-items-center">
    <button type="submit" (click)="onSubmit('save')" class="mt-5 btn btn-primary btn-lg btn-block" style="width: 240px;">
      Save Changes
    </button>
  </div>
  
  <div class="row justify-content-center align-items-center">
    <button type="button" data-bs-toggle="modal" data-bs-target="#deleteModal"
      class="mt-5 btn btn-primary btn-lg btn-block"
      style="width: 240px; background-color: rgba(52, 157, 157, 0.75); border:none; ">
      Delete Profile
    </button>
  </div>

  <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title title-modal">
            Do you really want to delete your profile?
          </h5>
        </div>

        <div class="modal-footer">
          <button type="submit" class="btn btn-modal" (click)="onSubmit('delete')" data-dismiss="modal">
            Delete
          </button>
          <button type="button" class="btn btn-modal-close" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Large modal -->

<div class="modal fade bd-example-modal-lg profile" tabindex="-1" role="dialog" aria-labelledby="profile"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title font" id="modal-basic-title">Upload Image</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          style="background-color: white; outline: none">
          <span aria-hidden="true">X</span>
        </button>
      </div>
      <app-cropper [photoType]="'profile'" (croppedImageData)="imageWasCropped($event)" [id]="id"></app-cropper>
    </div>
  </div>
</div>

<div class="modal fade bd-example-modal-lg poster1" tabindex="-1" role="dialog" aria-labelledby="poster1"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title font" id="modal-basic-title">Upload Image</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          style="background-color: white; outline: none">
          <span aria-hidden="true">X</span>
        </button>
      </div>
      <app-cropper img="poster1" (croppedImageData)="onImageSave($event)"></app-cropper>
    </div>
  </div>
</div>

<div class="modal fade bd-example-modal-lg poster2" tabindex="-1" role="dialog" aria-labelledby="poster2"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title font" id="modal-basic-title">Upload Image</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          style="background-color: white; outline: none">
          <span aria-hidden="true">X</span>
        </button>
      </div>
      <app-cropper img="poster2" (croppedImageData)="onImageSave($event)"></app-cropper>
    </div>
  </div>
</div>

<div class="modal fade bd-example-modal-lg poster3" tabindex="-1" role="dialog" aria-labelledby="poster3"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title font" id="modal-basic-title">Upload Image</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          style="background-color: white; outline: none">
          <span aria-hidden="true">X</span>
        </button>
      </div>
      <app-cropper img="poster3" (croppedImageData)="onImageSave($event)"></app-cropper>
    </div>
  </div>
</div>


