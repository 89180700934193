<form [formGroup]=this.formGroupCustom>
    <div class="dropdown form-group" *ngIf=!isEditor()>
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">{{displayText()}}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    
            <!-- <input type="checkbox" class="form-check-input" id="selectAll" (change)="selectAll($event)"
                        value="selectAll">
                    <label class="form-check-label" for="selectAll">
                        Select All
                    </label> -->
            <label class="form-check-label">
                Check all that apply
            </label>
    
            <div class="form-check" *ngFor="let value of values;">
                <input type="checkbox" class="form-check-input" [id]="value+category" [formArrayName]="formArrayNameCustom"
                    (change)="getValue($event)" [value]="value" [checked]="includesValue(value)">
                <label class="form-check-label" [for]="value">
                    <span *ngIf="renderHTML == true">
                        <span [innerHTML]="value"></span>
                    </span>
                    <span *ngIf="renderHTML != true">
                        {{value}}
                    </span>
                </label>
            </div>
        </div>
    </div>
    
    <div *ngIf=isEditor()>
    
        <div class="form-check mb-1" *ngFor="let value of values">
            <input type="checkbox" class="form-check-input" [id]="value+category" [formArrayName]="formArrayNameCustom"
                (change)="getValue($event)" [value]="value" [checked]="includesValue(value)">
            <label class="form-check-label text_font2 " [for]="value">
                <span *ngIf="renderHTML == true">
                    <span [innerHTML]="value"></span>
                </span>
                <span *ngIf="renderHTML != true">
                    {{value}}
                </span>
            </label>
        </div>
    
    
    
    </div>


</form>