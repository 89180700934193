import { Injectable } from '@angular/core';


export interface Profile {
  _id: string,
  Name: string,
  Position: string | null,
  BasedIn: string | null,
  Email: string,
  Link: string
}

@Injectable({
  providedIn: 'root'
})

export class BookmarksService {

  private bookmarks = new Array<Profile>();

  constructor() { 
    this.loadBookmarks();
  }

  getBookmarks(): Array<Profile> {
    this.loadBookmarks()
    return this.bookmarks;
  }

  loadBookmarks(): void {
    
    const savedBookmarks = localStorage.getItem('bookmarks');

    if(savedBookmarks) {
      this.bookmarks = JSON.parse(savedBookmarks);
    }
  }

  updateBookmarks(): void {
    localStorage.setItem('bookmarks', JSON.stringify(this.bookmarks));
  }

  addBookmark(user: Profile): void {

    if (this.findBookmark(user._id)) {

      return;

    } else {

      this.bookmarks.push(user);
      this.updateBookmarks();
      
    }
  }

  findBookmark(_id: string): boolean {

    if (this.bookmarks.find(user => user._id === _id)) {
      return true;
    } else {
      return false;
    }
  }

  removeBookmark(_id: string): void {
    const flag = this.findBookmark(_id);
    
    if (flag) {

      this.bookmarks = this.bookmarks.filter( profile => profile._id !== _id );
      this.updateBookmarks();
    } else {
      return;
    }

    //console.log(this.bookmarks)
  }

  removeBookmarks(_ids: string[]): void {
    _ids.forEach( id => {
      this.removeBookmark(id);
    });
    this.updateBookmarks();
  }

  listSize(): number {
    return this.bookmarks.length;
  }



}
