<!-- Footer -->

<div style="background-color: white;">
  <footer class="page-footer font-small teal pt-5  px-5 pb-4">

    <!-- Footer Text -->
    <div class="container-fluid text-center text-md-left">

      <!-- Grid row -->
      <div class="row">

        <!-- Grid column -->
        <div class="col-lg-11 col-md-12 mt-md-0 mt-3 d-flex align-items-center links_format">

          <!-- Content -->
          <ul class="nav d-flex justify-content-center ">

            <li class="nav-item">
              <a class="nav-link active linkStyle " routerLinkActive="active-link" routerLink="home">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link linkStyle" routerLink="editors" routerLinkActive="active-link">Find Editors</a>
            </li>
            <li class="nav-item">
              <a class="nav-link linkStyle" routerLink="post-job" routerLinkActive="active-link">Post a Job</a>
            </li>
            <li class="nav-item">
              <a class="nav-link linkStyle" routerLink="about" routerLinkActive="active-link">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link linkStyle" routerLink="guidelines" routerLinkActive="active-link">Our Guidelines</a>
            </li>
            <li class="nav-item">
              <a class="nav-link linkStyle" routerLink="signup" *ngIf=!isLogged() routerLinkActive="active-link">Sign
                Up</a>
            </li>
            <li class="nav-item">
              <a class="nav-link linkStyle" [routerLink]="'profile/'+getId()" *ngIf=isLogged()
                routerLinkActive="active-link">Profile</a>
            </li>

            <li class="nav-item">
              <a class="nav-link linkStyle" routerLink="contact" routerLinkActive="active-link">Contact Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link linkStyle" routerLink="signin" *ngIf=!isLogged() routerLinkActive="active-link">Log
                in</a>
            </li>
            <li class="nav-item">
              <a class="nav-link linkStyle" *ngIf=isLogged() routerLinkActive="active-link" (click)="logOut()">Log
                out</a>
            </li>

          </ul>
        </div>
        <!-- Grid column -->

        <div class=" d-flex justify-content-center w-100 d-md-none pb-3">
          <!-- Underline -->
          <hr style="width: 217px; border-width: 2px; border-color: lightgray;">

        </div>

        <!-- Grid column -->
        <div class="col-lg-1 col-md-12 mb-md-0 mb-3 text-right">

          <!-- Content -->
          <div class="d-flex justify-content-center social_margin">

            <!-- Social media -->
            <div class="d-flex  align-items-center  ">
              <!-- <a href="https://www.facebook.com"  target="_blank"><img class="social_images rounded-circle mr-2"
                  src="/assets/contact/facebook.jpg"></a> -->
              <a href="https://twitter.com/BipocEditors"  target="_blank"><img class="social_images rounded-circle mr-2"
                  src="/assets/contact/twitter.png"></a>
              <a href="https://www.instagram.com/bipoceditors/"  target="_blank"><img class="social_images rounded-circle"
                  src="/assets/contact/instagram.png"></a>
            </div>

          </div>

        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row -->

    </div>

  </footer> <!-- Footer -->
</div>