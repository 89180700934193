<div class="main">
    <!-- TOP PART (TITLE & DESCRIPTION) -->
    <div class="row d-flex justify-content-center mb-5">

        <!-- TITLE -->
        <div class="col-12">
            <h1 class="text-center mb-2 resp_title" style="font-weight: 550; font-size: 48px; color:#272343;">RESOURCES
            </h1>
            <!-- Underline -->
            <div class="d-flex justify-content-center">
                <hr class="mb-4" style="width: 217px; border-width: 50px; border-color: lightgray;">
            </div>
        </div>

        <!-- DESCRIPTION -->
        <div class="col-12">
            <p class="description">
                We believe we are stronger when we stand together in collective action. We encourage you to check out
                these documentary community resources and support initiatives that seek to break down barriers for
                filmmakers who are BIPOC, living with disabilities, undocumented, female, non-binary, LGBTQ+, and from other
                marginalized communities.
            </p>
        </div>
    </div>

    <!-- /TOP PART (TITLE & DESCRIPTION) -->

    <div class="d-flex justify-content-center cards" style="flex-wrap: wrap;">
        <!-- CARDS -->
        <app-resource-card [resource]="resource" *ngFor="let resource of resources "></app-resource-card>
    </div>
</div>