import { Component, OnInit } from '@angular/core';
import { Client , Body14} from '../../../api.client';
@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {

  resources: Body14[];

  constructor(private apiClient: Client) { }

  ngOnInit(): void {
    this.getResources();
  }

  getResources():void {

    this.apiClient.resources2().subscribe(d => {

      this.resources=d.body;
      //console.log(d.body[0].link)

      
    })

  }


}
