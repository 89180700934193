import { Component, OnInit, Input } from '@angular/core';
import { Client } from '../../../../api.client';

@Component({
  selector: 'app-resource-card',
  templateUrl: './resource-card.component.html',
  styleUrls: ['./resource-card.component.scss']
})
export class ResourceCardComponent implements OnInit {

  @Input() resource:any;

  constructor( private apiClient: Client) { 

  }

  ngOnInit(): void {
  
  }

}
