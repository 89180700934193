import { Component, HostListener, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Client, Anonymous8, Anonymous19, Body13, Body15 } from '../../../../api.client';
import { Observable } from 'rxjs';
import { LinksService } from "../../../services/links.service";

// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from 'swiper/core';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  images1: Observable<Anonymous8>;
  img: String;
  img1: String;
  img2: String;
  img3: String;
  img4: String;
  img5: String;
  img6: String;
  img7: String;
  img8: String;
  img9: String;
  img10: String;
  img11: String;

  endorsers: Body15[];

  partners: Body13[];

  highlights: any = [];


  innerWidth: number;

  constructor(private apiClient: Client, private linkService: LinksService) {

    this.apiClient.images().subscribe(images => {
      this.img1 = images.body[0];
      this.img2 = images.body[1];
      this.img3 = images.body[2];
      this.img4 = images.body[3];
      this.img5 = images.body[4];
      this.img6 = images.body[5];
      this.img7 = images.body[6];
      this.img8 = images.body[7];
      this.img9 = images.body[8];
      this.img10 = images.body[9];

      this.img11 = images.body[10];
    });

    this.apiClient.partner2().subscribe(d => {
      this.partners = d.body;
      //console.log(d.body)
    })




  }


  getHighlight() {
    return this.apiClient.highlight3().pipe(map(h => h.body)).subscribe(d => {
      this.highlights = d;
    }, e => {
      console.log(e)
    });
  }


  getEndorser() {
    this.apiClient.endorser().subscribe(d => {
      // Move the Brown Girls endorser to the top of the list. ID: 6228e141d9ca8dbdbead5412

      const BROWN_GIRLS_ENDORSER = "6228e141d9ca8dbdbead5412";

      const endorsers = []
      
      d.body.forEach(endorser => endorser._id === BROWN_GIRLS_ENDORSER ? endorsers.unshift(endorser) : endorsers.push(endorser));

      // this.endorsers = d.body;

      this.endorsers = endorsers;
    });


  }



  ngOnInit(): void {
    this.getEndorser();
    this.getHighlight();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }


  onSwiper(swiper) {
    swiper.pagination.init(swiper);

  }
  onSlideChange() {
  }


  breakpoints(): number  {

    if(this.innerWidth <= 760)
      return 1;
    else if(this.innerWidth <= 1080 && this.innerWidth > 760)
      return 2;
    else if(this.innerWidth <= 2000 && this.innerWidth > 1080)
      return 3
    else
      return 4;

  }


  verifyURL(link: string) {
    return this.linkService.validateURL(link);
  }
}
